import React from 'react';
import { FaRegUserCircle, FaGreaterThan } from 'react-icons/fa';
import InitialHeader from '../InitialHeader';

import { auth } from '../../services/api';

class SettingsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
            email: '',
            role: '',
            currentProfileLoaded: false
        };
    };

    componentDidMount() {
        this.getCurrentProfile();
    }

    getCurrentProfile = async () => {
        try {
            const res = await auth.get('auth/current-profile');
            
            this.setState({ fullName: res.data.full_name, email: res.data.email, role: res.data.role, currentProfileLoaded: true });
        } catch (err) {
            console.log(err);
        }
    }

    logout = async () => {
        try {
            const res = await auth.post('auth/logout');

            this.props.history.push('/');
        } catch (err) {
            console.log(err)
        }
    };

    render() {
        return (
            <div>
                <InitialHeader title="Settings" history={this.props.history} />
                {this.state.currentProfileLoaded &&
                    <div className="settings-page">
                        <div className="settings-page__profile-header">
                            <FaRegUserCircle size={50} />
                            <div className="settings-page__profile-name" style={{ width: '80%', wordBreak: 'break-word', textAlign: 'center' }}>{this.state.fullName}</div>
                            <div className="settings-page__profile-email">{this.state.email}</div>
                        </div>
                        <div className="settings-page__body">
                            {
                                this.state.role === 'admin' && 
                                <button className="settings-page__button" onClick={() => this.props.history.push('/admin-panel')}>
                                    <div>Admin Panel</div>
                                    <FaGreaterThan />
                                </button>
                            }
                            <button className="settings-page__button" onClick={() => this.props.history.push('/settings/personal-info')}>
                                <div>Personal Info</div>
                                <FaGreaterThan />
                            </button>
                            <button className="settings-page__button" onClick={() => this.props.history.push('/settings/security')}>
                                <div>Security</div>
                                <FaGreaterThan />
                            </button>
                            <button className="settings-page__button" onClick={() => this.props.history.push('/settings/help')}>
                                <div>Help</div>
                                <FaGreaterThan />
                            </button>
                            <button className="settings-page__button" onClick={() => this.props.history.push('/settings/about')}>
                                <div>About</div>
                                <FaGreaterThan />
                            </button>
                            <button className="settings-page__button" onClick={() => this.logout()}>
                                <div>Logout</div>
                                <FaGreaterThan />
                            </button>
                        </div>
                    </div>
                }
            </div>
        );
    }
};

export default SettingsPage;
