import React, {useEffect, useState} from 'react';
import { Redirect, Route } from 'react-router-dom';
import LoadingPage from '../components/LoadingPage';

import { auth } from '../services/api';

export const PublicRoute = ({
    component: Component,
    ...rest
}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    let mounted = true;

    useEffect(() => {
        checkAuth();

        return () => {
            mounted = false;
        }
    }, [isAuthenticated, isLoading]);

    const checkAuth = async () => {
        try {
            const res = await auth.get('auth/check-auth');

            if(mounted) {
                if (res.data.isAuthenticated) {
                    setIsAuthenticated(true);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            }  
        } catch (err) {
            if (mounted){
                setIsLoading(false);
            }
        }
    }

    return(
        isLoading ? 
        <LoadingPage /> :
        <Route {...rest} component={(props) => (
            isAuthenticated ? (
                <Redirect to="/dashboard" />
            ) : (
                <Component {...props} />
            )
        )}/>
    );
};

export default PublicRoute;
