import React from 'react';
import InitialHeader from '../InitialHeader';
import ResetPasswordForm from './ResetPasswordForm';
import logo from '../../assets/images/logo.png';

class ResetPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        console.log(this.props);
    }
    onSubmit = () => {
        this.props.history.push('/');
    };

    render () {
        return (
            <div className="password-recovery-page">
                <InitialHeader title="Reset your Password" history={this.props.history} />
                <div className="password-recovery">
                    <img src={logo}  className="password-recovery__img" style={{ marginTop: '70px', width: '125px' }} />
                    <h3 className="password-recovery__title" style={{ margin: 0 }}>We have sent you a four digit code.</h3>
                    <ResetPasswordForm onSubmit={this.onSubmit} userId={this.props.match.params.id} />
                </div>
            </div>
        );
    }
}

export default ResetPasswordPage;
