import React from 'react';
import validator from 'validator';
import { FaBusinessTime } from "react-icons/fa";

import { market } from '../../services/api';

class SetAuctionTimes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            auctionsDay: '',
            auctionsPerHour: '',
            errors: [],
            errorType: ''
        };
    };

    componentDidMount() {
        this.getAuctionsDay();
    };

    getAuctionsDay = async () => {
        try {
            const res = await market.get('market/get-auctions-day');

            this.setState({ auctionsDay: res.data.res.auction_times});
            this.setState({ auctionsPerHour: res.data.res.auction_hour_times});
        } catch (err) {
            console.log(err);
        }
    };

    onAuctionsDayChange = (e) => {
        const auctionsDay = e.target.value;

        this.setState(() => ({ auctionsDay }));
    }

    onAuctionsPerHourChange = (e) => {
        const auctionsPerHour = e.target.value;

        this.setState(() => ({ auctionsPerHour }));
    }

    validateFields = () => {
        this.setState(() => ({errors: [], errorType: ''}));

        if (!validator.isInt(this.state.auctionsDay.toString(), { min: 1, max: 24 })) {
            this.setState(() => ({errors: ['You must enter a valid integer from 1 to 24'], errorType: 'auctionsDay'}));
            return false;
        }

        if (!validator.isInt(this.state.auctionsPerHour.toString(), { min: 1, max: 60 })) {
            this.setState(() => ({errors: ['You must enter a valid integer from 1 to 60'], errorType: 'auctionsPerHour'}));
            return false;
        }

        return true;
    };

    onSubmit = async (e) => {
        e.preventDefault();

        if (this.validateFields()) {
            try {
                const res = await market.post('market/admin/set-auctions-day', {
                    auctionsDay: this.state.auctionsDay,
                    auctionsPerHour: this.state.auctionsPerHour
                });
    
                this.props.history.push('/admin-panel');
            } catch (err) {
                this.setState(() => ({errors: [err.response.data.errors[0].message], errorType: ''}));
            }
        }
    };

    render() {
        return (
            <form className="register__box" style={{ marginTop: '80px' }} onSubmit={this.onSubmit}>
                <div className="register__box__form" style={{ paddingBottom: '25px' }}>
                    {
                        this.state.errorType === 'auctionsDay'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }} >
                            <FaBusinessTime />
                            <input className="register__text-area" style={{ width: '215px' }} type="number" placeholder="Auctions Per Day (Max. 24)" value={this.state.auctionsDay} onChange={this.onAuctionsDayChange} />
                        </div>
                        :
                        <div className="input-box">
                            <FaBusinessTime />
                            <input className="register__text-area" style={{ width: '215px' }} type="number" placeholder="Auctions Per Day (Max. 24)" value={this.state.auctionsDay} onChange={this.onAuctionsDayChange} />
                        </div>
                    }
                    {
                        this.state.errorType === 'auctionsPerHour'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }} >
                            <FaBusinessTime />
                            <input className="register__text-area" style={{ width: '215px' }} type="number" placeholder="Auctions Per Hour (Max. 60)" value={this.state.auctionsPerHour} onChange={this.onAuctionsPerHourChange} />
                        </div>
                        :
                        <div className="input-box">
                            <FaBusinessTime />
                            <input className="register__text-area" style={{ width: '215px' }} type="number" placeholder="Auctions Per Hour (Max. 60)" value={this.state.auctionsPerHour} onChange={this.onAuctionsPerHourChange} />
                        </div>
                    }
                </div>
                <div className="register__error-warning">
                        {this.state.errors.length > 0 ? this.state.errors : ''}
                </div>
                <div>
                    <div className="register__box__register-button">
                        <button className="register__box__register-button__style">Save</button>
                    </div>
                </div>
            </form>
        );
    }
};

export default SetAuctionTimes;
