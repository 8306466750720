import React from 'react';
import { Link } from 'react-router-dom';
import { FaList } from 'react-icons/fa';
import RecentActivityItem from './RecentActivityItem';

import { transactions } from '../../services/api';

class RecentActivity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            recentActivityList: [],
            recentActivityListLoaded: false
        };
    }

    componentDidMount() {
        this.getRecentActivity();
    }

    getRecentActivity = async () => {
        try {
            const res = await transactions.get('transactions/get-recent-activity');
            this.setState({ recentActivityList: res.data.result });
            this.setState({ recentActivityListLoaded: true });
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        return(
            <div className="content-container" style={{ marginTop: '25px' }}>
                <div className="list-header">
                    <div className="show-for-mobile"><span className="list-item__body-title">Recent Activity</span></div>
                    <div className="show-for-desktop"><span className="list-item__body-title">Recent Activity</span></div>
                </div>
                <div className="list-body">
                    {
                        this.state.recentActivityListLoaded && (this.state.recentActivityList.length === 0 ?
                        (
                            <div className="list-item list-item--message">
                                <span>Here you'll find all your recent activity.</span>
                            </div>
                        ) :
                        (
                            this.state.recentActivityList.reverse().map((activity, index) => {
                                return <RecentActivityItem key={index} {...activity} />;
                            })
                        ))
                    }
                    <div className="list-item list-item--message" >
                        <Link style={{textDecoration: 'none'}} to="/all-activity"><button className="list-item__button-activity"><FaList /> View all activity</button></Link>
                    </div>
                </div>
            </div>
        );
    }
};

export default RecentActivity;
