import React, {useEffect, useState} from 'react';
import { Redirect, Route } from 'react-router-dom';
import HeaderAdmin from '../components/HeaderAdmin';

import { auth } from '../services/api';

const AdminRoute = ({ 
    component: Component,
    hasHeader,
    ...rest
}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [role, setRole] = useState('user');
    const [isLoading, setIsLoading] = useState(true);
    let mounted = true;

    useEffect(() => {
        checkAuth();

        return () => {
            mounted = false;
        }
    }, [isAuthenticated, isLoading]);

    const checkAuth = async () => {
        try {
            const res = await auth.get('auth/check-auth');

            console.log(res.data.role);

            if(mounted) {
                if (res.data.isAuthenticated) {
                    setIsAuthenticated(true);
                    setRole(res.data.role);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            }  
        } catch (err) {
            if (mounted){
                setIsLoading(false);
            }
        }
    }

    return(
        isLoading ?
        <div>Loading...</div> :
        <Route {...rest} component={(props) => (
            isAuthenticated ? (
                role === 'admin' ? (
                    <div>
                        {hasHeader ? <HeaderAdmin /> : null}    
                        <Component {...props} />
                    </div>
                ) : (
                    <Redirect to="/dashboard" />
                )
            ) : (
                <Redirect to="/" />
            )
        )}/>
    );
};

export default AdminRoute;