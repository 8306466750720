import React from 'react';
import RegisterPageForm from './RegisterPageForm';
import logo from '../../assets/images/logo.png';

class RegisterPage extends React.Component {
    onSubmit = () => {
        this.props.history.push('/');
    };

    render () {
        return (
            <div className="register">
                <div className="register__img">
                    <img src={logo} width="200" height="200" />
                </div>
                <div className="register__box">
                    <RegisterPageForm onSubmit={this.onSubmit} />
                </div>
            </div>
        );
    };
}

export default RegisterPage;
