import React from 'react';
import Modal from 'react-modal';
import PinInput from 'react-pin-input';
import QrReader from 'react-qr-reader';
import validator from 'validator';
import { FooterSecondaryPaySend } from '../FooterSecondary';

import { auth } from '../../services/api';
import { transactions } from '../../services/api';

import logo from '../../assets/images/logo.png';
import sucessIcon from '../../assets/images/green_checkmark.svg';
import failIcon from '../../assets/images/fail_icon.svg';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        width: '95%',
        padding: '0px'
    }
};

const initialState = {
    subtitle: '',
    modalIsOpen: false,
    dataLoaded: false,
    result: '',
    errors: [],
    uncofirmed: true,
    success: false,
    pinCode: '',
    pinConfirmed: false,
    cryptos: [],
    cryptosLoaded: false,
    selected: ''
};

const verifyPin = async (pin, callback) => {
    try {
        const res = await auth.post('auth/verify-pin', {
            pin
        });

        callback(null, res.data.result)
    } catch (err) {
        callback(err);
    }
}

Modal.setAppElement(document.getElementById('app'));

class PayPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.getCryptosAvailable();
    }

    getCryptosAvailable = async () => {
        try {
            const res = await transactions.get('transactions/get-cryptos');
            this.setState({ cryptos: res.data.result });
            this.setState({ cryptosLoaded: true });
        } catch (err) {
            console.log(err);
        }
    }

    resetState = () => {
        this.setState(initialState);
    }

    openModal = () => {
        this.setState(() => ({ modalIsOpen: true }));
    };

    afterOpenModal = () => {
        this.state.subtitle.style.color = '#000000';
    }

    closeModal = () => {
        this.setState(() => ({ modalIsOpen: false  }));
        this.resetState();
    }

    validateFields = (code) => {
        this.setState(() => ({errors: []}));

        if (!validator.matches(code, /^[a-zA-Z0-9]*:[a-zA-Z0-9]{32}\?amount\=(\d+(\.\d{0,2})?|\.?\d{1,2})$/)) {
            this.setState(() => ({ errors: ['You must scan a valid QR Code'] }));
            return false;
        }

        return true;
    }

    completeTransaction = async () => {
        try {
            if (this.state.result.split(':')[0] === 'Euros') {
                if (this.state.selected === ''){
                    this.setState(() => ({errors: ['You must select a valid cryptocurrency']}));

                    return false;
                }

                if (Number.parseFloat(this.state.result.split('=')[1]/this.getCryptoPrice(this.state.selected)) < 0.01) {
                    this.setState(() => ({errors: ['Invalid amount conversion to cryptocurrency (smaller than 0.01), try another cryptocurrency!']}));

                    return false;
                }

                const res = await transactions.post('transactions/make-payment', {
                    payment_payload: `${this.state.selected}:${this.state.result.split(':')[1].split('?')[0]}?amount=${Number.parseFloat(this.state.result.split('=')[1]/this.getCryptoPrice(this.state.selected))}`
                });
            } else {
                const res = await transactions.post('transactions/make-payment', {
                    payment_payload: this.state.result
                });
            }

            return true;
        } catch (err) {
            this.setState(() => ({errors: [err.response.data.errors[0].message]}));
            
            return false;
        }
    }

    confirmScan = () => {
        this.setState({ uncofirmed: false });
    }

    declineScan = () => {
        this.closeModal();
        this.props.history.push('/dashboard');
    }

    handleScan = data => {
        if (data) {
            if (this.validateFields(data)){
                this.setState({
                    result: data
                });
                this.setState({
                    dataLoaded: true
                });
                this.openModal();
            }
        }
    }

    handleError = err => {
        console.log(err)
    }

    onPinCodeChange = (pinCode) => {
        this.setState(() => ({ pinCode }))
    }

    checkCode = (value, index) => {
        verifyPin(value ,(error, result) => {
            if (error) {
                this.setState({ errors: [error.response.data.errors[0].message] });
                this.setState({ pinConfirmed: false });
            }

            if (result) {
                const transactionResult = this.completeTransaction();

                transactionResult.then((res) => {
                    if (res) {
                        this.setState({ success: true });
                    } else {
                        this.setState({ success: false });
                    }
                    this.setState({ pinConfirmed: true });
                });
            } else {
                this.setState({ errors: 'Wrong PIN. Try again! ' });
                this.setState({ pinConfirmed: true });
                this.setState({ success: false });
            }
        });
    }

    getCryptoPrice = (crypto_name) => {
        let price;

        this.state.cryptos.map((el) => {
            if (el.name === crypto_name) {
                price = el.price;
            }
        });

        return price;
    }

    getCryptoSymbol = (crypto_name) => {
        let symbol;

        this.state.cryptos.map((el) => {
            if (el.name === crypto_name) {
                symbol = el.symbol;
            }
        });

        return symbol;
    }

    getCryptoTransactionCost = (crypto_name) => {
        let transaction_cost;

        this.state.cryptos.map((el) => {
            if (el.name === crypto_name) {
                transaction_cost = el.transaction_cost;
            }
        });

        return transaction_cost;
    }

    onSelectChange = (e) => {

        e.stopPropagation();

        const { value } = e.target;

        this.setState({ selected: value });
    }

    render () {
        return (
            <div>
                {this.state.dataLoaded &&
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.closeModal}
                        style={customStyles}
                        contentLabel="QR Code Modal"
                    >
                    <div className="pay-page-modal">
                    {
                        this.state.uncofirmed ? 
                        <div>
                            <h2 ref={_subtitle => (this.state.subtitle = _subtitle)} style={{ margin: 0, marginTop: '5px' }}>Transaction Confirmation</h2>
                                {
                                    this.state.result.split(':')[0] === 'Euros' ?
                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <select className="request-page__select" style={{ alignSelf: 'center', width: '75%' }} name="cryptos" id="cryptos" onChange={this.onSelectChange} >
                                            <option key={''} value={''}>{}</option>
                                            {
                                                this.state.cryptos.map((crypto) => {
                                                    return <option key={crypto.name} value={crypto.name}>{crypto.symbol + ' ' + crypto.name}</option>
                                                })
                                            }
                                            </select>
                                            <h3 style={{ textAlign: 'center', margin: '0px', marginBottom: '10px' }}>Transaction Details</h3>
                                            <div><span style={{ fontWeight: 'bold' }}>Amount (in €):</span> {this.state.result.split('=')[1]}</div>
                                            <div><span style={{ fontWeight: 'bold' }}>Fee:</span> {this.state.selected === '' ? '' : Number.parseFloat((this.state.result.split('=')[1]/this.getCryptoPrice(this.state.selected)) * this.getCryptoTransactionCost(this.state.selected))} {this.getCryptoSymbol(this.state.selected)}</div>
                                            <div><span style={{ fontWeight: 'bold' }}>Total:</span> {this.state.selected === '' ? '' : Number.parseFloat(this.state.result.split('=')[1]/this.getCryptoPrice(this.state.selected)) + Number.parseFloat((this.state.result.split('=')[1]/this.getCryptoPrice(this.state.selected)) * this.getCryptoTransactionCost(this.state.selected))} {this.getCryptoSymbol(this.state.selected)}</div>
                                            <div><span style={{ fontWeight: 'bold' }}>From:</span> You</div>
                                            <div><span style={{ fontWeight: 'bold' }}>To:</span> {this.state.result.split(':')[1].split('?')[0]}</div>
                                        </div>
                                        <div className="modal-buttons" style={{ marginBottom: '5px' }}>
                                            <button className="modal-buttons__decline" onClick={this.declineScan}>Decline</button>
                                            <button className="modal-buttons__confirm" onClick={this.confirmScan}>Confirm</button>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <h3 style={{ textAlign: 'center', margin: '0px', marginBottom: '10px' }}>Transaction Details</h3>
                                            <div><span style={{ fontWeight: 'bold' }}>Amount:</span> {this.state.result.split('=')[1]} {this.getCryptoSymbol(this.state.result.split(':')[0])}</div>
                                            <div><span style={{ fontWeight: 'bold' }}>Fee:</span> {this.state.result.split('=')[1] * this.getCryptoTransactionCost(this.state.result.split(':')[0])} {this.getCryptoSymbol(this.state.result.split(':')[0])}</div>
                                            <div><span style={{ fontWeight: 'bold' }}>Total:</span> {Number.parseFloat(this.state.result.split('=')[1]) + Number.parseFloat(this.state.result.split('=')[1] * this.getCryptoTransactionCost(this.state.result.split(':')[0]))} {this.getCryptoSymbol(this.state.result.split(':')[0])}</div>
                                            <div><span style={{ fontWeight: 'bold' }}>From:</span> You</div>
                                            <div><span style={{ fontWeight: 'bold' }}>To:</span> {this.state.result.split(':')[1].split('?')[0]}</div>
                                        </div>
                                        <div className="modal-buttons" style={{ marginBottom: '5px' }}>
                                            <button className="modal-buttons__decline" onClick={this.declineScan}>Decline</button>
                                            <button className="modal-buttons__confirm" onClick={this.confirmScan}>Confirm</button>
                                        </div>
                                    </div>
                                }
                                
                        </div> : <div>
                            {
                                !this.state.pinConfirmed ? 
                                <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <img src={logo} style={{ height: '125px', marginTop: '5px' }}/>
                                    <h2 ref={_subtitle => (this.state.subtitle = _subtitle)} style={{ margin: 0 }}>Enter your PIN</h2>
                                    <PinInput
                                        style={{ marginBottom: '5px' }}
                                        type="numeric"
                                        length={6}
                                        focus
                                        inputMode="number"
                                        value={this.state.pinCode}
                                        onChange={this.onPinCodeChange}
                                        onComplete={this.checkCode}
                                    />
                                </div> :
                                this.state.success ? 
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>   
                                    <img src={sucessIcon} alt="Success Icon" width="150px" height="150px" />
                                    <h3 style={{ textAlign: 'center', margin: '0px', marginBottom: '10px', fontWeight: 'bold' }}>Transaction Complete</h3>
                                </div> : 
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>   
                                    <img src={failIcon} alt="Fail Icon" width="150px" height="150px" />
                                    <h3 style={{ textAlign: 'center', margin: '0px', marginBottom: '10px', fontWeight: 'bold' }}>Transaction Failed</h3>
                                    <h2>{this.state.errors}</h2>
                                </div>
                            }
                        </div>
                    }
                    </div>
                </Modal>
                }
                {!this.state.modalIsOpen &&
                    <div className="box-reader">
                        <div className="box-reader__item">
                            <QrReader 
                                delay={300}
                                onError={this.handleError}
                                onScan={this.handleScan}
                                className="box-reader__qr-reader"
                            />
                            <h3>Point the camera to the QR Code and wait...</h3>
                            <div className="register__error-warning">
                                    {this.state.errors.length > 0 ? this.state.errors : ''}
                            </div>
                        </div>
                    </div>
                }
                <FooterSecondaryPaySend titleOne="Pay" titleSecond="Send" {...this.props} />
            </div>
        );
    }
};

export default PayPage;
