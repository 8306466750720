import React from 'react';
import moment from 'moment';

class ActivityItem extends React.Component {
    
    render () {
        return(
            <div className="list-item">
                <div>
                    <h3 className="list-item__title">Transaction {this.props.status === 'completed' ? 'Complete' : 'Pending'}</h3>
                    {
                        this.props.type === 'received' ? (
                            this.props.privacy === 'public' ? (
                                <span className="list-item__subtitle">You {this.props.type} <span style={{ color: 'green' }}>{this.props.amount}</span> {this.props.crypto_name} from {this.props.full_name}.</span> 
                            ) : (
                                this.props.privacy === 'semi-private' ? (
                                    <span className="list-item__subtitle">You {this.props.type} <span style={{ color: 'green' }}>{this.props.amount}</span> {this.props.crypto_name} from user with ID {this.props.user_id}.</span> 
                                ) : (
                                    <span className="list-item__subtitle">You {this.props.type} <span style={{ color: 'green' }}>{this.props.amount}</span> {this.props.crypto_name}.</span> 
                                )
                            )
                        ) : (
                            this.props.privacy === 'public' ? (
                                <span className="list-item__subtitle">You {this.props.type} <span style={{ color: '#990000' }}>{this.props.amount}</span> {this.props.crypto_name} to {this.props.full_name}.</span>
                            ) : (
                                this.props.privacy === 'semi-private' ? (
                                    <span className="list-item__subtitle">You {this.props.type} <span style={{ color: '#990000' }}>{this.props.amount}</span> {this.props.crypto_name} to user with ID {this.props.user_id}.</span>
                                ) : (
                                    <span className="list-item__subtitle">You {this.props.type} <span style={{ color: '#990000' }}>{this.props.amount}</span> {this.props.crypto_name}.</span>
                                )
                            )
                        )
                    }
                    <div>
                        <div className="list-item__notification-date">{moment.unix(this.props.date).format('DD/MM/YYYY')}</div>
                        <div className="list-item__notification-date">{moment.unix(this.props.date).format('HH:mm')}</div>
                    </div>
                </div>
            </div>
        );
    };
};

export default ActivityItem;
