import React from 'react';
import validator from 'validator';
import { FaCoins } from "react-icons/fa";

class RequestPageForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            amount: '',
            selected: '',
            errors: [],
            errorType: ''
        };
    };

    validateFields = () => {
        this.setState(() => ({errors: [], errorType: ''}));

        if (!validator.isDecimal(this.state.amount, { decimal_digits: '0,2' })) {
            this.setState(() => ({errors: ['You must enter a valid amount'], errorType: 'amount'}));
            return false;
        }

        if (validator.isEmpty(this.state.selected)) {
            this.setState(() => ({errors: ['You must choose one cryptocurrency'], errorType: 'selected'}));
            return false;
        }

        return true;
    }

    onSubmit = (e) => {
        e.preventDefault();

        if (this.validateFields()) {

            this.props.onSubmit(this.state.amount, this.state.selected);
        }
    };

    onSelectChange = (e) => {
        const { value } = e.target;

        this.setState({ selected: value });
    }

    onAmountChange = (e) => {
        const amount = e.target.value;

        this.setState(() => ({ amount }));
    };

    render() {
        return (
            <form id="request_form" className="request-page__form" onSubmit={this.onSubmit}>
                <div className="input-box">
                    <FaCoins />
                    <input
                    style={{border: "none", backgroundColor: '#e3e3e3'}}
                    name="amount"
                    type="text"
                    placeholder="Amount"
                    value={this.state.amount}
                    onChange={this.onAmountChange}
                    />
                </div>
                <select className="request-page__select" name="cryptos" id="cryptos" onChange={this.onSelectChange} >
                <option key={''} value={''}>{}</option>
                {
                    this.props.cryptos.map((crypto) => {
                        return <option key={crypto.name} value={crypto.name}>{crypto.symbol + ' ' + crypto.name}</option>
                    })
                }
                </select>
                <div id="div_error" className="register__error-warning">
                        {this.state.errors.length > 0 ? this.state.errors : ''}
                </div>
                <div>
                    <button className="request-page__button">Generate QR Code</button>
                </div>
            </form>
        );
    }
};

export default RequestPageForm;
