import React from 'react';
import { FaEnvelope } from 'react-icons/fa';
import LoginPageForm from './LoginPageForm';
import logo from '../../assets/images/logo.png';


class LoginPage extends React.Component {
    onSubmit = () => {
        this.props.history.push('/dashboard');
    };

    onHelpClick = () => {
        this.props.history.push('/contact-us');
    }

    render () {
        return (
            <div className="login">
                <div className="login__img">
                    <img src={logo} width="200" height="200" />
                </div>
                <div className="login__box">
                    <LoginPageForm onSubmit={this.onSubmit} />
                </div>
                <div className="login__help">
                    <button className="login__help__button" onClick={this.onHelpClick}><FaEnvelope /> Need help?</button>
                </div>  
            </div>
        );
    }
};

export default LoginPage;
