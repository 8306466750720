import React from 'react';
import { Link } from 'react-router-dom';
import { FaBell, FaHome, FaCog } from 'react-icons/fa';

const Header = () => (
  <header className="header__main-menu">
    <div className="header__main-menu__content">
      <Link to="/settings">
        <button className="button button--link"><FaCog /></button>
      </Link>
    </div>
  </header>
);

export default Header;