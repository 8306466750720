import React from 'react';
import moment from 'moment';

class NotificationItem extends React.Component {
    
    render () {
        return(
            <div className="list-item">
                <div>
                    <h3 className="list-item__title">{this.props.title}</h3>
                    <span className="list-item__subtitle">{this.props.description}</span>
                    <div className="list-item__notification-date">{moment.unix(this.props.createdAt).format('DD/MM/YYYY')}</div>
                    <div className="list-item__notification-date">{moment.unix(this.props.createdAt).format('HH:mm')}</div>
                </div>
            </div>
        );
    };
};

export default NotificationItem;