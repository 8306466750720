import React from 'react';
import InitialHeader from '../InitialHeader';

export class PermissionsPage extends React.Component {

    render() {
        return (
            <div>
                <InitialHeader title="Permissions" history={this.props.history} />
                <div className="content-container">
                    <div>
                        Page with permissions that the user needs to give and ability to change them...
                    </div>
                </div>
            </div>
        );
    }
};

export default PermissionsPage;
