import React from 'react';
import CryptoList from './CryptoList';
import RecentActivity from './RecentActivity';

import { wallet } from '../../services/api';

class MainMenuPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            walletList: [],
            walletListFilters: [],
            fiatBalance: '',
            systemPhase: '',
            cryptoLoaded: false,
            cryptoFiltersLoaded: false,
            fiatBalanceLoaded: false
        };
    }

    componentDidMount() {
        this.getWallet();
        this.getWalletFilters();
        this.getFiatBalance();
    }

    getWallet = async () => {
        try {
            const res = await wallet.get('wallet/get-wallet');
            this.setState({ walletList: res.data.result });
            this.setState({ systemPhase: res.data.phase });
            this.setState({ cryptoLoaded: true });
        } catch (err) {
            console.log(err);
        }
    }

    getWalletFilters = async () => {
        try {
            const res = await wallet.get('wallet/get-wallet-filters');
            this.setState({ walletListFilters: res.data.result });
            this.setState({ cryptoFiltersLoaded: true });
        } catch (err) {
            console.log(err);
        }
    }

    getFiatBalance = async () => {
        try {
            const res = await wallet.get('wallet/get-fiat-balance');
            this.setState({ fiatBalance: res.data.result });
            this.setState({ fiatBalanceLoaded: true });
        } catch (err) {
            console.log(err);
        }
    }

    render () {
        return (
            <div className="content-with-vertical-margin">
                {
                    this.state.cryptoLoaded && this.state.cryptoFiltersLoaded && this.state.fiatBalanceLoaded && 
                    <div>
                        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                            <div style={{ backgroundColor: '#E0E0E0', borderRadius: '15px', boxShadow: '5px 5px #f4f4f4', display: 'flex', flexDirection: 'column', height: '100px', width: '80%' }}>
                                <div style={{ alignItems: 'center', display: 'flex', height: '30%', justifyContent: 'center', width: '100%', fontFamily: 'Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif', fontSize: '18px', fontWeight: 'bold', fontStyle: 'normal', fontVariant: 'normal', fontWeight: '700', lineHeight: '26.4px', color: '#1c88bf' }}>
                                    <span>Current Fiat Balance</span>
                                </div>
                                <div style={{ alignItems: 'center', display: 'flex', height: '70%', justifyContent: 'center', fontWeight: 'bold', fontSize: '30px', width: '100%' }}>
                                    <span>{this.state.fiatBalance} €</span>
                                </div>
                            </div>
                        </div>
                        <RecentActivity />
                        <CryptoList cryptos={this.state.walletList} cryptosFilter={this.state.walletListFilters} phase={this.state.systemPhase} history={this.props.history} />
                    </div>
                }
            </div>
        );
    }
};

export default MainMenuPage;
