import React from 'react';
import InitialHeader from '../InitialHeader';
import validator from 'validator';
import { FaLock } from 'react-icons/fa';

import { auth } from '../../services/api';

export class SecurityPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newPIN: '',
            newPINConfirmation: '',
            errors: [],
            errorType: ''
        };
    }

    onNewPINChange = (e) => {
        const newPIN = e.target.value;

        this.setState(() => ({ newPIN }));
    }

    onNewPINConfirmationChange = (e) => {
        const newPINConfirmation = e.target.value;

        this.setState(() => ({ newPINConfirmation }));
    }

    validateFields = () => {
        this.setState(() => ({errors: [], errorType: ''}));

        if (this.state.newPIN.length !== 0) {
            if (this.state.newPIN !== this.state.newPINConfirmation) {
                this.setState(() => ({errors: ['Both PIN codes must match'], errorType: 'new_pin'}));
                return false;
            }
            if (!validator.isNumeric(this.state.newPIN) || !validator.isLength(this.state.newPIN, { min: 6, max: 6 })) {
                this.setState(() => ({errors: ['You must enter a valid PIN (6 digits)'], errorType: 'new_pin'}));
                return false;
            }
        }

        return true;
    }

    onSubmit = async (e) => {
        e.preventDefault();

        console.log('doing it...')

        if (this.validateFields()) {
            if (this.state.newPIN.length !== 0) {
                try {
                    const res = await auth.post('auth/edit-pin-code', {
                        pin_code: this.state.newPIN
                    });
        
                    this.props.history.push('/settings');
                } catch (err) {
                    this.setState(() => ({errors: [err.response.data.errors[0].message], errorType: ''}));
                }
            } else {
                this.props.history.push('/settings');
            }
        }
    }

    render() {
        return (
            <div>
                <InitialHeader title="Security" history={this.props.history} />
                <div className="content-container" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '60vh', justifyContent: 'space-between', width: '100vw' }}>
                    <div className="personal-info-page" style={{ alignItems: 'center', flexDirection: 'column', justifyContent: 'space-around' }}>
                        {
                            this.state.errorType === 'new_pin'
                            ? 
                            <div className="input-box-security" style={{ borderColor: 'red' }}>
                                <FaLock />
                                <input className="register__text-area" style={{ borderColor: 'red', backgroundColor: '#E0E0E0', width: '100%' }} type="text" placeholder="New PIN Code" value={this.state.newPIN} onChange={this.onNewPINChange} />
                            </div>
                            :
                            <div className="input-box-security">
                                <FaLock />
                                <input className="register__text-area" style={{ backgroundColor: '#E0E0E0', width: '100%' }} type="text" placeholder="New PIN Code" value={this.state.newPIN} onChange={this.onNewPINChange} />
                            </div>
                        }
                        {
                            this.state.errorType === 'new_pin'
                            ? 
                            <div className="input-box-security" style={{ borderColor: 'red' }}>
                                <FaLock />
                                <input className="register__text-area" style={{ borderColor: 'red', backgroundColor: '#E0E0E0', width: '100%' }} type="text" placeholder="New PIN Code Confirmation" value={this.state.newPINConfirmation} onChange={this.onNewPINConfirmationChange} />
                            </div>
                            :
                            <div className="input-box-security">
                                <FaLock />
                                <input className="register__text-area" style={{ backgroundColor: '#E0E0E0', width: '100%' }} type="text" placeholder="New PIN Code Confirmation" value={this.state.newPINConfirmation} onChange={this.onNewPINConfirmationChange} />
                            </div>
                        }
                        <div className="register__error-warning">
                            {this.state.errors.length > 0 ? this.state.errors : ''}
                        </div>
                        <div>
                            <div className="register__box__register-button">
                                <button className="register__box__register-button__style" onClick={this.onSubmit}>Change PIN Code</button>
                            </div>
                        </div>
                        </div>
                </div>
            </div>
        );
    }
};

export default SecurityPage;
