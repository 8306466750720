import React from 'react';
import validator from 'validator';
import { FaUserPlus, FaEnvelope, FaLock, FaPhone, FaUser } from "react-icons/fa";

import { auth } from '../../services/api';

class RegisterPageForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            invitation_code: '',
            phoneNumber: '',
            email: '',
            fullName: '',
            password: '',
            confirmPassword: '',
            pin: '',
            errors: [],
            errorType: ''
        };
    };

    onInvitationCodeChange = (e) => {
        const invitation_code = e.target.value;

        this.setState(() => ({ invitation_code }))
    }

    onPhoneNumberChange = (e) => {
        const phoneNumber = e.target.value;

        this.setState(() => ({ phoneNumber }));
    }

    onEmailChange = (e) => {
        const email = e.target.value;

        this.setState(() => ({ email }));
    }

    onFullNameChange = (e) => {
        const fullName = e.target.value;

        this.setState(() => ({ fullName }));
    }

    onPasswordChange = (e) => {
        const password = e.target.value;

        this.setState(() => ({ password }))
    }

    onConfirmPasswordChange = (e) => {
        const confirmPassword = e.target.value;

        this.setState(() => ({ confirmPassword }))
    }

    onPINChange = (e) => {
        const pin = e.target.value;

        this.setState(() => ({ pin }))
    }

    validateFields = () => {
        this.setState(() => ({errors: [], errorType: ''}));

        if (!validator.isNumeric(this.state.invitation_code) || !validator.isLength(this.state.invitation_code, { min: 6, max: 6 })) {
            this.setState(() => ({errors: ['You must enter a valid invitation code (6 digits)'], errorType: 'invitation_code'}));
            return false;
        }
        if (!validator.isMobilePhone(this.state.phoneNumber, 'pt-PT')) {
            this.setState(() => ({errors: ['You must enter a valid phone number from Portugal'], errorType: 'phone_number'}));
            return false;
        }
        if (!validator.isEmail(this.state.email)) {
            this.setState(() => ({errors: ['You must enter a valid email address'], errorType: 'email'}));
            return false;
        }
        if (!this.state.fullName.match(/^[a-zA-Z \u00C0-\u00FF]*$/) || !validator.isLength(this.state.fullName, { min: 5 })) {
            this.setState(() => ({errors: ['You must enter a valid full name with only letters and at least 5 characters'], errorType: 'full_name'}));
            return false;
        }
        if (this.state.password !== this.state.confirmPassword) {
            this.setState(() => ({errors: ['Both password must match'], errorType: 'password'}));
            return false;
        }
        if (!validator.isStrongPassword(this.state.password) || !validator.isLength(this.state.password, { max: 20 })) {
            this.setState(() => ({errors: ['You must enter a valid password (length between 8 and 20, at least 1 lowercase and 1 uppercase letter, 1 number and 1 symbol)'], errorType: 'password'}));
            return false;
        }
        if (!validator.isNumeric(this.state.pin) || !validator.isLength(this.state.pin, { min: 6, max: 6 })) {
            this.setState(() => ({errors: ['You must enter a valid PIN (6 digits)'], errorType: 'pin'}));
            return false;
        }

        return true;
    }

    onSubmit = async (e) => {
        e.preventDefault();

        if (this.validateFields()) {
            try {
                const res = await auth.post('auth/register', {
                    invitation_code: this.state.invitation_code,
                    phone_number: this.state.phoneNumber,
                    email: this.state.email,
                    full_name: this.state.fullName,
                    password: this.state.password,
                    pin: this.state.pin
                });
    
                this.props.onSubmit();
            } catch (err) {
                this.setState(() => ({errors: [err.response.data.errors[0].message], errorType: ''}));
            }
        }
    }

    render() {
        return (
            <form className="register__box" onSubmit={this.onSubmit}>
                <div className="register__box__form">
                    {
                        this.state.errorType === 'invitation_code'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }} >
                            <FaUserPlus />
                            <input className="register__text-area" type="text" placeholder="Invitation Code" value={this.state.invitation_code} onChange={this.onInvitationCodeChange} />
                        </div>
                        :
                        <div className="input-box">
                            <FaUserPlus />
                            <input className="register__text-area" type="text" placeholder="Invitation Code" value={this.state.invitation_code} onChange={this.onInvitationCodeChange} />
                        </div>
                    }
                    {
                        this.state.errorType === 'phone_number'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }} >
                            <FaPhone />
                            <input className="register__text-area" type="text" placeholder="Phone Number" value={this.state.phoneNumber} onChange={this.onPhoneNumberChange} />
                        </div>
                        :
                        <div className="input-box">
                            <FaPhone />
                            <input className="register__text-area" type="text" placeholder="Phone Number" value={this.state.phoneNumber} onChange={this.onPhoneNumberChange} />
                        </div>
                    }   
                    {
                        this.state.errorType === 'email'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }}>
                            <FaEnvelope />
                            <input className="register__text-area" type="text" placeholder="Email" value={this.state.email} onChange={this.onEmailChange} maxLength="500" />
                        </div>
                        :
                        <div className="input-box">
                            <FaEnvelope />
                            <input className="register__text-area" type="text" placeholder="Email" value={this.state.email} onChange={this.onEmailChange} maxLength="500" />
                        </div>
                    }
                    {
                        this.state.errorType === 'full_name'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }}>
                            <FaUser />
                            <input className="register__text-area" type="text" placeholder="Full Name" value={this.state.fullName} onChange={this.onFullNameChange} />
                        </div>
                        :
                        <div className="input-box">
                            <FaUser />
                            <input className="register__text-area" type="text" placeholder="Full Name" value={this.state.fullName} onChange={this.onFullNameChange} />
                        </div>
                    }                    
                    {
                        this.state.errorType === 'password'
                        ?
                        <>
                            <div className="input-box" style={{ borderColor: 'red' }}>
                                <FaLock />
                                <input className="register__text-area" type="password" placeholder="Password" value={this.state.password} onChange={this.onPasswordChange} />
                            </div>
                            <div className="input-box" style={{ borderColor: 'red' }}>
                                <FaLock />
                                <input className="register__text-area" type="password" placeholder="Confirm Password" value={this.state.confirmPassword} onChange={this.onConfirmPasswordChange} />
                            </div>
                        </>
                        :
                        <>
                            <div className="input-box">
                                <FaLock />
                                <input className="register__text-area" type="password" placeholder="Password" value={this.state.password} onChange={this.onPasswordChange} />
                            </div>
                            <div className="input-box">
                                <FaLock />
                                <input className="register__text-area" type="password" placeholder="Confirm Password" value={this.state.confirmPassword} onChange={this.onConfirmPasswordChange} />
                            </div>
                        </>
                    }
                    {
                        this.state.errorType === 'pin'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }} >
                            <FaLock />
                            <input className="register__text-area" type="text" placeholder="PIN" value={this.state.pin} onChange={this.onPINChange} />
                        </div>
                        :
                        <div className="input-box">
                            <FaLock />
                            <input className="register__text-area" type="text" placeholder="PIN" value={this.state.pin} onChange={this.onPINChange} />
                        </div>
                    }
                </div>
                <div className="register__error-warning">
                        {this.state.errors.length > 0 ? this.state.errors : ''}
                </div>
                <div>
                    <div className="register__box__register-button">
                        <button className="register__box__register-button__style">Create Account</button>
                    </div>
                    <div className="register__button__warning">
                        By creating an account, you are accepting our terms and conditions.
                    </div>
                </div>
            </form>
        );
    }
};

export default RegisterPageForm;
