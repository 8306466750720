import React from 'react';
import moment from 'moment';
import { FaRegTrashAlt } from 'react-icons/fa';

import { market } from '../../services/api';

export class OrderListItemOpen extends React.Component {

    cancelOrder = async () => {
        try {
            const res = await market.post('market/cancel-order', {
                order_id: this.props.order_id,
                side: this.props.side
            });

            if (res.data.res === true) {
                this.props.history.push('/my-orders-open');
            }
        } catch (err) {
            console.log(err);
        }
    }

    render () {
        return(
            <div className="portfolio__list-item">
                <div className="portfolio__list-item__body" style={{ flexDirection: 'column', width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <div className="portfolio__list-item__body-title">{`${this.props.name} (€)`}</div>
                        <div>
                            <button className="send-page__button" style={{ height: '100%', width: '100%' }} onClick={this.cancelOrder}><FaRegTrashAlt />Cancel Order</button>
                        </div>
                    </div>
                    <div className="portfolio__list-item__body-subtitle"><span style={this.props.side === 'SELL' ? {color: 'red'} : {color: 'green'}}>{this.props.side === 'SELL' ? 'Sell' : 'Buy'}</span> {this.props.quantity} {this.props.side === 'SELL' ? 'at' : 'for'} {this.props.type === 'MARKET' ? 'market price' : `${this.props.price} €`}</div>
	            <div className="portfolio__list-item__body-subtitle">Good Until: {this.props.cancelled === null ? 'Cancelled' : moment.unix(this.props.cancelled).format('D MMM YYYY')}</div>
                </div>
                <div className="portfolio__list-item__body-profit-loss">
                    <div className="portfolio__list-item__body__date">{moment.unix(this.props.date).format('D MMM YYYY HH:mm')}</div>
                </div>          
            </div>
        );
    };
};

export class OrderListItemClosed extends React.Component {
    
    render () {
        return(
            <div className="portfolio__list-item">
                <div className="portfolio__list-item__body">
                    <div className="portfolio__list-item__body-title">{`${this.props.name} (€)`}</div>
                </div>
                <div className="portfolio__list-item__body">
                    <div className="portfolio__list-item__body-subtitle"><span style={this.props.side === 'SELL' ? {color: 'red'} : {color: 'green'}}>{this.props.side === 'SELL' ? 'Sold' : 'Bought'}</span> {this.props.quantity} {this.props.side === 'SELL' ? 'at' : 'for'} {this.props.price} €</div>
                </div>
                <div className="portfolio__list-item__body-profit-loss">
                    <div className="portfolio__list-item__body__date">{moment.unix(this.props.date).format('D MMM YYYY HH:mm')}</div>
                </div>
            </div>
        );
    };
};
