import React from 'react';
import { Link } from 'react-router-dom';
import InitialHeader from '../InitialHeader';

export class HelpPage extends React.Component {

    render() {
        return (
            <div>
                <InitialHeader title="Help" history={this.props.history} />
                <div style={{  alignItems: 'center', display: 'flex', flexDirection: 'column', marginTop: '60px' }}>
                    <span className="about-page-title" style={{ textAlign: 'center' }}>Need Help?</span>
                    <span className="about-page-text" style={{ fontWeight: 'bold', fontSize: '12px', textAlign: 'center' }}>Choose one of the following sections and follow the instructions</span>
                </div>
                <div className="content-container" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', marginBottom: '70px', marginTop: '20px', width: '100vw' }}>
                    <div className="help-page-container">
                        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '70%', justifyContent: 'space-around', width: '100%' }}>
                            <span className="help-page-title">Manage your account</span>
                            <div className="help-page">
                                <Link to="/settings/help/manage-account/login-logout" style={{ textDecoration: 'none' }}>
                                    <span className="help-page-text">Log in and out of Crypta Project</span>
                                </Link>
                                <Link to="/settings/help/manage-account/change-info-password" style={{ textDecoration: 'none' }}>
                                    <span className="help-page-text">Change your personal information or password</span>
                                </Link>
                                <Link to="/settings/help/manage-account/reset-password" style={{ textDecoration: 'none' }}>
                                    <span className="help-page-text">Reset your password</span>
                                </Link>
                                <Link to="/settings/help/manage-account/change-pin" style={{ textDecoration: 'none' }}>
                                    <span className="help-page-text">Change your PIN code</span>
                                </Link>
                                <Link to="/settings/help/manage-account/contact-login" style={{ textDecoration: 'none' }}>
                                    <span className="help-page-text">Contact support while logged in</span>
                                </Link>
                                <Link to="/settings/help/manage-account/contact-logout" style={{ textDecoration: 'none' }}>
                                    <span className="help-page-text">Contact support while logged out</span>
                                </Link>
                            </div>
                        </div>
                        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '70%', justifyContent: 'space-around', width: '100%' }}>
                            <span className="help-page-title">Wallet</span>
                            <div className="help-page">
                                <Link to="/settings/help/wallet/change-wallet-filters" style={{ textDecoration: 'none' }}>
                                    <span className="help-page-text">Change your wallet filters</span>
                                </Link>
                                <Link to="/settings/help/wallet/check-all-activity" style={{ textDecoration: 'none' }}>
                                    <span className="help-page-text">Check your wallet activity</span>
                                </Link>
                                <Link to="/settings/help/wallet/check-crypto-info" style={{ textDecoration: 'none' }}>
                                    <span className="help-page-text">Check cryptocurrency information</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="help-page-container">
                        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '70%', justifyContent: 'space-around', width: '100%' }}>
                            <span className="help-page-title">Transactions</span>
                            <div className="help-page">
                                <Link to="/settings/help/transactions/make-payment" style={{ textDecoration: 'none' }}>
                                    <span className="help-page-text">Make a payment</span>
                                </Link>
                                <Link to="/settings/help/transactions/send-crypto" style={{ textDecoration: 'none' }}>
                                    <span className="help-page-text">Send cryptocurrency to another user</span>
                                </Link>
                                <Link to="/settings/help/transactions/request-crypto" style={{ textDecoration: 'none' }}>
                                    <span className="help-page-text">Request an amount of cryptocurrency</span>
                                </Link>
                            </div>
                        </div>
                        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '70%', justifyContent: 'space-around', width: '100%' }}>
                            <span className="help-page-title">Market</span>
                            <div className="help-page">
                                <Link to="/settings/help/market/make-order" style={{ textDecoration: 'none' }}>
                                    <span className="help-page-text">Make order (buy/sell and market/limit) in the market</span>
                                </Link>
                                <Link to="/settings/help/market/check-portfolio" style={{ textDecoration: 'none' }}>
                                    <span className="help-page-text">Check portfolio with open and closed orders</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default HelpPage;