import React from 'react';
import validator from 'validator';
import { FaEnvelope, FaPhone } from "react-icons/fa";

import { auth } from '../../services/api';

class ForgotPasswordForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            phoneNumber: '',
            email: '',
            errors: [],
            errorType: ''
        };
    };

    onPhoneNumberChange = (e) => {
        const phoneNumber = e.target.value;

        this.setState(() => ({ phoneNumber }));
    }

    onEmailChange = (e) => {
        const email = e.target.value;

        this.setState(() => ({ email }));
    }

    validateFields = () => {
        this.setState(() => ({errors: [], errorType: ''}));
        let errorsAux = [];
        let errorTypeAux = '';

        if (!validator.isMobilePhone(this.state.phoneNumber, 'pt-PT')) {
            errorsAux = errorsAux.concat('You must enter a valid phone number from Portugal');
            errorTypeAux = 'phone_number';
        }
        if (!validator.isEmail(this.state.email)) {
            errorsAux = errorsAux.concat('You must enter a valid email address.');
            errorTypeAux = 'email';
        }

        if (errorsAux.length === 2) {
            this.setState(() => ({errors: ['You must enter a valid phone number from Portugal or email address.'], errorType: 'phone-number_email'}));
            return {
                condition: false,
                length: 2
            };
        } else if (errorsAux.length === 1) {
            this.setState(() => ({ errors: errorsAux, errorType: errorTypeAux }));
            return {
                condition: true,
                length: 1,
                errorAuxType: errorTypeAux
            };
        } else {
            return {
                condition: true,
                length: 0
            };
        }
    }

    onSubmit = async (e) => {
        e.preventDefault();

        const res = this.validateFields();

        if (res.condition) {
            let reqConfig = {};
            if (res.length === 0) {
                reqConfig = {
                    phone_number: this.state.phoneNumber,
                    email: this.state.email
                }
            } else {
                if (res.errorAuxType === 'phone_number') {
                    reqConfig = {
                        email: this.state.email
                    }
                } else {
                    reqConfig = {
                        phone_number: this.state.phoneNumber
                    }
                }
            }

            try {
                const res = await auth.post('auth/password-recovery', reqConfig);

                this.props.onSubmit(res.data.id_user);
            } catch (err) {
                this.setState(() => ({errors: [err.response.data.errors[0].message], errorType: ''}));
            }
        }
    };

    render () {
        return (
            <form className="password-recovery__form" onSubmit={this.onSubmit}>
                <div className="password-recovery__inputs-container">
                    <div className="input-box">
                        <FaPhone />
                        <input style={{border: "none"}} type="text" placeholder="Phone Number" value={this.state.phoneNumber} onChange={this.onPhoneNumberChange} />
                    </div>
                    <div className="password-recovery__form__input">or</div>
                    <div className="input-box">
                        <FaEnvelope />
                        <input style={{border: "none"}} type="text" placeholder="Email" value={this.state.email} onChange={this.onEmailChange} />
                    </div>
                </div>
                <div className="login__error-warning">
                    {this.state.errors.length > 0 && (this.state.errorType !== 'email' && this.state.errorType !== 'phone_number') ? this.state.errors : ''}
                </div>
                <div>
                    <button className="password-recovery__button">Confirm</button>
                </div>
            </form>
        );
    };
};

export default ForgotPasswordForm;