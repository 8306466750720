import React from 'react';
import InitialHeader from '../InitialHeader';
import NotificationItem from './NotificationItem';

const listTest = [{
    title: 'New device has been authorized',
    description:'You have sucessfully authorized a new device or in a new location to sign into your account.',
    createdAt: '1616269649'
}, {
    title: 'Sucessfully Login From New IP',
    description:'The system has detected that your account is logged in from an unused IP address.',
    createdAt: '1616269644'
}, {
    title: 'New device has been authorized 1',
    description:'You have sucessfully authorized a new device or in a new location to sign into your account.',
    createdAt: '1615405649'
}
]

export class NotificationsPage extends React.Component {

    render() {
        return (
            <div>
                <InitialHeader title="Notifications" history={this.props.history} />
                <div className="content-container" style={{ marginTop: '60px' }}>
                    <div className="list-body" style={{ borderTop: '1px solid #e5e5e5' }}>
                    {
                        listTest.length === 0 ? (
                        <div className="list-item list-item--message">
                            <span>You have no notifications.</span>
                        </div>
                        ) : (
                            listTest.map((notification) => {
                                return <NotificationItem key={notification.title} {...notification} />;
                            })
                        )
                    }
                    </div>
                </div>
            </div>
        );
    }
};

export default NotificationsPage;
