import React from 'react';
import Modal from 'react-modal';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        width: '75%',
        height: '30%',
        padding: '0px'
    }
};

function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay; 
}

class CryptoListItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false
        }
    }
    
    openModal = () => {
        this.setState(() => ({ modalIsOpen: true }));
    };

    closeModal = () => {
        this.setState(() => ({ modalIsOpen: false }));
    }

    render () {
        return(
            <div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Crypto Modal"
                >
                    <div className="pay-page-modal">
                        <div><span style={{ fontWeight: 'bold' }}>Name:</span> {this.props.name}</div>
                        <div><span style={{ fontWeight: 'bold' }}>Symbol:</span> {this.props.symbol}</div>
                        {
                            this.props.phase === 'transactions' &&
                            <div><span style={{ fontWeight: 'bold' }}>Price:</span> {this.props.price}</div>
                        }
                        <div><span style={{ fontWeight: 'bold' }}>Privacy:</span> {this.props.privacy}</div>
                        <div><span style={{ fontWeight: 'bold' }}>Fee</span>: {(Number.parseFloat(this.props.transaction_cost) * 100)} %</div>
                        <div><span style={{ fontWeight: 'bold' }}>Settlement Time:</span> {this.props.settlement_time/60/60 === 0 ? 'None' : `${this.props.settlement_time/60/60} Hour(s)`}</div>
                    </div>
                </Modal>
                <div className="list-item" style={{ cursor: 'pointer' }} onClick={this.openModal}>
                    <h3 className="list-item__title" style={{ fontSize: 'medium' }}>{this.props.name + '   '}     <span style={{ color: 'grey' }}>{this.props.symbol}</span></h3>
                    <h3 className="list-item__data">{this.props.quantity}</h3>
                </div>
            </div>
        );
    };
};

export default CryptoListItem;
