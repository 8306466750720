import React from 'react';
import validator from 'validator';
import { Link } from 'react-router-dom';
import { FaLock, FaPhone } from "react-icons/fa";

import { auth } from '../../services/api';

class LoginPageForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phoneNumber: '',
            password: '',
            errors: [],
            errorType: ''
        };
    };

    onPhoneNumberChange = (e) => {
        const phoneNumber = e.target.value;
        
        this.setState(() => ({ phoneNumber }));
    };

    onPasswordChange = (e) => {
        const password = e.target.value;

        this.setState(() => ({ password }))
    };

    validateFields = () => {
        this.setState(() => ({errors: [], errorType: ''}));

        if (!validator.isMobilePhone(this.state.phoneNumber, 'pt-PT')) {
            this.setState(() => ({errors: ['You must enter a valid phone number from Portugal'], errorType: 'phone_number'}));
            return false;
        }
        if (!validator.isStrongPassword(this.state.password) || !validator.isLength(this.state.password, { max: 20 })) {
            this.setState(() => ({errors: ['You must enter a valid password (length between 8 and 20, at least 1 lowercase and 1 uppercase letter, 1 number and 1 symbol'], errorType: 'password'}));
            return false;
        }

        return true;
    }

    onSubmit = async (e) => {
        e.preventDefault();

        if (this.validateFields()) {
            try {
                const res = await auth.post('auth/login', {
                    phone_number: this.state.phoneNumber,
                    password: this.state.password
                });
    
                this.props.onSubmit();
            } catch (err) {
                this.setState(() => ({errors: [err.response.data.errors[0].message], errorType: ''}));
            }
        }
    };

    render() {
        return (
            <form className="login__box" onSubmit={this.onSubmit}>
                <div className="login__box__form">
                    {
                        this.state.errorType === 'phone_number'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }}>
                            <FaPhone />
                            <input className="login__text-area" type="text" placeholder="Phone Number" value={this.state.phoneNumber} onChange={this.onPhoneNumberChange} />
                        </div>
                        :
                        <div className="input-box">
                            <FaPhone />
                            <input className="login__text-area" type="text" placeholder="Phone Number" value={this.state.phoneNumber} onChange={this.onPhoneNumberChange} />
                        </div>
                    } 
                    {
                        this.state.errorType === 'phone_number'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }}>
                            <FaLock />
                            <input className="register__text-area" type="password" placeholder="Password" value={this.state.password} onChange={this.onPasswordChange} />
                        </div>
                        :
                        <div className="input-box">
                            <FaLock />
                            <input className="register__text-area" type="password" placeholder="Password" value={this.state.password} onChange={this.onPasswordChange} />
                        </div>
                    } 
                </div>
                <div className="login__error-warning">
                    {this.state.errors.length > 0 ? this.state.errors : ''}
                </div>
                <div>
                    <div className="login__box__login-button">
                        <button className="login__box__login-button__style">Login</button>
                    </div>
                    <Link className="login__forgot__button" to="/forgot-password">
                            <button className="login__forgot__button__forgot">Forgot password?</button>
                    </Link>
                </div>
            </form>
        );
    }
};

export default LoginPageForm;