import React from 'react';
import InitialHeader from '../InitialHeader';
import { FaEnvelope } from "react-icons/fa";

export class AboutPage extends React.Component {

    render() {
        return (
            <div>
                <InitialHeader title="About" history={this.props.history} />
                <div className="content-container" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', marginBottom: '70px', marginTop: '60px', width: '100vw' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '70%', justifyContent: 'space-around' }}>
                        <span className="about-page-title">Who we are?</span>
                        <div className="about-page" style={{ alignSelf: 'center', height: '70%' }}>
                            <span className="about-page-text">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </span>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '70%', justifyContent: 'space-around' }}>
                        <span className="about-page-title">What we do?</span>
                        <div className="about-page" style={{ alignSelf: 'center', height: '70%' }}>
                            <span className="about-page-text">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </span>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '70%', justifyContent: 'space-around' }}>
                        <span className="about-page-title">How we do it?</span>
                        <div className="about-page" style={{ alignSelf: 'center', height: '70%' }}>
                            <span className="about-page-text">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </span>
                        </div>
                    </div>
                    <div style={{ marginTop: '15px' }}>
                        <div className="login__help">
                            <button className="login__help__button" onClick={() => (this.props.history.push('/settings/about/contact-us'))} style={{ display: 'flex' }}><FaEnvelope style={{ paddingRight: '2px' }} /> Contact Us!</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default AboutPage;
