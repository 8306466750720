import React from 'react';
import { FaCoins } from "react-icons/fa";

export class TradeBuyPageForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phoneNumber: '',
            amount: '',
            cryptos: ['Crypto#1 (€)', 'Crypto#2 (€)', 'Crypto#3 (€)', 'Crypto#4 (€)', 'Crypto#5 (€)'],
            availableBalance: '400',
            availableBalanceCurrency: ''
        };
    };

    onSubmit = (e) => {
        e.preventDefault();

        this.props.onSubmit();
    };

    onAmountChange = (e) => {
        const amount = e.target.value;

        if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
            this.setState(() => ({ amount }));
        }
    };

    onChangeSelect = (e) => {
        console.log(e.target.value);

        this.setState({ availableBalanceCurrency: e.target.value })
    }

    render() {
        return (
            <form className="trade-order__form" onSubmit={this.onSubmit}>
                <select className="trade-order__select" name="cryptos" id="cryptos" onChange={this.onChangeSelect}>
                {
                    this.state.cryptos.map((crypto) => {
                        return <option key={crypto} value={crypto}>{crypto}</option>
                    })
                }
                </select>
                <div className="input-box">
                    <FaCoins />
                    <input
                    style={{border: "none"}}
                    type="text"
                    placeholder="Amount"
                    value={this.state.amount}
                    onChange={this.onAmountChange}
                    />
                </div>
                <div className="trade-order__info-container">
                    <div className="trade-order__info-container__row">
                        <div style={{ fontWeight: 'bold', fontSize: '12px' }}>Available Balance </div>
                        <div style={{ fontWeight: 'bold', fontSize: '12px' }}>{this.state.availableBalance} €</div>
                    </div>
                    <div className="trade-order__info-container__row">
                        <div style={{ fontWeight: 'bold', fontSize: '12px' }}>Required </div>
                        <div style={{ fontWeight: 'bold', fontSize: '12px' }}>{parseFloat(this.state.availableBalance)*parseFloat(this.state.amount)} €</div>
                    </div>
                </div>
                <div>
                    <button className="trade-order__button-buy">Place BUY Trade</button>
                </div>
            </form>
        );
    }
};

export class TradeSellPageForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phoneNumber: '',
            amount: '',
            cryptos: ['Crypto#1 (€)', 'Crypto#2 (€)', 'Crypto#3 (€)', 'Crypto#4 (€)', 'Crypto#5 (€)'],
            availableBalance: '400',
            availableBalanceCurrency: ''
        };
    };

    onSubmit = (e) => {
        e.preventDefault();

        this.props.onSubmit();
    };

    onAmountChange = (e) => {
        const amount = e.target.value;

        if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
            this.setState(() => ({ amount }));
        }
    };

    onChangeSelect = (e) => {
        console.log(e.target.value);

        this.setState({ availableBalanceCurrency: e.target.value })
    }

    render() {
        return (
            <form className="trade-order__form" onSubmit={this.onSubmit}>
                <select className="trade-order__select" name="cryptos" id="cryptos" onChange={this.onChangeSelect}>
                {
                    this.state.cryptos.map((crypto) => {
                        return <option key={crypto} value={crypto}>{crypto}</option>
                    })
                }
                </select>
                <div className="input-box">
                    <FaCoins />
                    <input
                    style={{border: "none"}}
                    type="text"
                    placeholder="Amount"
                    value={this.state.amount}
                    onChange={this.onAmountChange}
                    />
                </div>
                <div className="trade-order__info-container">
                    <div className="trade-order__info-container__row">
                        <div style={{ fontWeight: 'bold', fontSize: '12px' }}>Available Balance </div>
                        <div style={{ fontWeight: 'bold', fontSize: '12px' }}>{this.state.availableBalance} {this.state.availableBalanceCurrency.split(' ')[0]}</div>
                    </div>
                </div>
                <div>
                    <button className="trade-order__button">Place SELL Trade</button>
                </div>
            </form>
        );
    }
};