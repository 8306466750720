import React from 'react';
import moment from 'moment';
import numeral from 'numeral';

export class PositionListItemOpen extends React.Component {
    
    render () {
        return(
            <div className="portfolio__list-item">
                <div className="portfolio__list-item__body">
                    <div className="portfolio__list-item__body-title">{this.props.name}</div>
                    <div className="portfolio__list-item__body-title">{this.props.price}</div>
                </div>
                <div className="portfolio__list-item__body">
                    <div className="portfolio__list-item__body-subtitle"><span style={{color: 'green'}}>Buy</span> 20.0 at 51.2</div>
                    <div className="portfolio__list-item__body-subtitle">P&L <span style={{color: 'green'}}>0.2</span></div>
                </div>
                <div className="portfolio__list-item__body-profit-loss">
                    <div><span className="portfolio__list-item__body__circle">TP</span> 5.1</div>
                    <div style={{paddingLeft: '10px'}}><span className="portfolio__list-item__body__circle">SL</span> Not Set</div>
                </div>
            </div>
        );
    };
};

export class PositionListItemClosed extends React.Component {
    
    render () {
        return(
            <div className="portfolio__list-item">
                <div className="portfolio__list-item__body">
                    <div className="portfolio__list-item__body-title">{this.props.name}</div>
                </div>
                <div className="portfolio__list-item__body">
                    <div className="portfolio__list-item__body-subtitle"><span style={{color: 'red'}}>Sell</span> 20.0 at 51.8</div>
                    <div className="portfolio__list-item__body-subtitle">P&L <span style={{color: 'green'}}>0.4</span></div>
                </div>
                <div className="portfolio__list-item__body">
                    <div className="portfolio__list-item__body__date">12 Dec 2020 18:49</div>
                </div>
            </div>
        );
    };
};