import React from 'react';
import validator from 'validator';
import { FaPencilAlt, FaCoins } from "react-icons/fa";

import { crypto } from '../../services/api';

class EditCryptocurrency extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: props.location.name || '',
            price: props.location.price.toString() || '',
            volume: props.location.volume.toString() || '',
            privacy: props.location.privacy || '',
            transaction_cost: props.location.transaction_cost.toString() || '',
            settlement_time: parseInt(props.location.settlement_time).toString() || '',
            errors: [],
            errorType: ''
        };
    };

    onNameChange = (e) => {
        const name = e.target.value;

        this.setState(() => ({ name }));
    }

    onSymbolChange = (e) => {
        const symbol = e.target.value;

        this.setState(() => ({ symbol }));
    }

    onPriceChange = (e) => {
        const price = e.target.value;

        this.setState(() => ({ price }));
    }

    onVolumeChange = (e) => {
        const volume = e.target.value;

        this.setState(() => ({ volume }));
    }

    onPrivacyChange = (e) => {
        const privacy = e.target.value;

        this.setState(() => ({ privacy }));
    }

    onTransactionCostChange = (e) => {
        const transaction_cost = e.target.value;

        this.setState(() => ({ transaction_cost }));
    }

    onSettlementTimeChange = (e) => {
        const settlement_time = e.target.value;

        this.setState(() => ({ settlement_time }));
    }

    validateFields = () => {
        this.setState(() => ({errors: [], errorType: ''}));

        if (!validator.matches(this.state.price, /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/)) {
            this.setState(() => ({errors: ['You must enter a valid price'], errorType: 'price'}));
            return false;
        }
        if (!validator.isInt(this.state.volume)) {
            this.setState(() => ({errors: ['You must enter a valid cap value'], errorType: 'volume'}));
            return false;
        }
        if (!validator.matches(this.state.privacy, /\b(private|semi-private|public)\b/)) {
            this.setState(() => ({errors: ['You must enter a valid privacy (private, semi-private, public'], errorType: 'privacy'}));
            return false;
        }
        if (!validator.isInt(this.state.transaction_cost, { min: 0, max: 100 })) {
            this.setState(() => ({errors: ['You must enter a valid fee value (percentage: between 0 and 100)'], errorType: 'transaction_cost'}));
            return false;
        }
        if (!validator.isInt(this.state.settlement_time)) {
            this.setState(() => ({errors: ['You must enter a valid settlement time'], errorType: 'settlement_time'}));
            return false;
        }

        return true;
    }

    onSubmit = async (e) => {
        e.preventDefault();

        if (this.validateFields()) {
            try {
                const res = await crypto.post('crypto/admin/edit-crypto', {
                    name: this.state.name,
                    price: this.state.price,
                    volume: this.state.volume,
                    privacy: this.state.privacy,
                    transaction_cost: this.state.transaction_cost,
                    settlement_time: this.state.settlement_time
                });

                this.props.history.push('/admin-panel');
            } catch (err) {
                this.setState(() => ({errors: [err.response.data.errors[0].message], errorType: ''}));
            }
        }
    }

    render() {
        return (
            <form className="register__box" style={{ marginTop: '80px' }} onSubmit={this.onSubmit}>
                <div className="register__box__form" style={{ paddingBottom: '25px' }}>
                    {
                        this.state.errorType === 'name'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }}>
                            <FaCoins />
                            <input className="register__text-area" type="text" placeholder="Crypto name to edit" value={this.state.name} onChange={this.onNameChange} />
                        </div>
                        :
                        <div className="input-box">
                            <FaCoins />
                            <input className="register__text-area" type="text" placeholder="Crypto name to edit" value={this.state.name} onChange={this.onNameChange} />
                        </div>
                    }
                    {
                        this.state.errorType === 'price'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }}>
                            <FaPencilAlt />
                            <input className="register__text-area" type="text" placeholder="Price" value={this.state.price} onChange={this.onPriceChange} />
                        </div>
                        :
                        <div className="input-box">
                            <FaPencilAlt />
                            <input className="register__text-area" type="text" placeholder="Price" value={this.state.price} onChange={this.onPriceChange} />
                        </div>
                    }
                    {
                        this.state.errorType === 'volume'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }}>
                            <FaPencilAlt />
                            <input className="register__text-area" type="text" placeholder="Cap" value={this.state.volume} onChange={this.onVolumeChange} />
                        </div>
                        :
                        <div className="input-box">
                            <FaPencilAlt />
                            <input className="register__text-area" type="text" placeholder="Cap" value={this.state.volume} onChange={this.onVolumeChange} />
                        </div>
                    }
                    {
                        this.state.errorType === 'privacy'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }}>
                            <FaPencilAlt />
                            <input className="register__text-area" type="text" placeholder="Privacy" value={this.state.privacy} onChange={this.onPrivacyChange} />
                        </div>
                        :
                        <div className="input-box">
                            <FaPencilAlt />
                            <input className="register__text-area" type="text" placeholder="Privacy" value={this.state.privacy} onChange={this.onPrivacyChange} />
                        </div>
                    }
                    {
                        this.state.errorType === 'transaction_cost'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }}>
                            <FaPencilAlt />
                            <input className="register__text-area" type="text" placeholder="Fee" value={this.state.transaction_cost} onChange={this.onTransactionCostChange} />
                        </div>
                        :
                        <div className="input-box">
                            <FaPencilAlt />
                            <input className="register__text-area" type="text" placeholder="Fee" value={this.state.transaction_cost} onChange={this.onTransactionCostChange} />
                        </div>
                    }
                    {
                        this.state.errorType === 'settlement_time'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }}>
                            <FaPencilAlt />
                            <input className="register__text-area" type="text" placeholder="Settlement Time" value={this.state.settlement_time} onChange={this.onSettlementTimeChange} />
                        </div>
                        :
                        <div className="input-box">
                            <FaPencilAlt />
                            <input className="register__text-area" type="text" placeholder="Settlement Time" value={this.state.settlement_time} onChange={this.onSettlementTimeChange} />
                        </div>
                    }
                </div>
                <div className="register__error-warning">
                        {this.state.errors.length > 0 ? this.state.errors : ''}
                </div>
                <div>
                    <div className="register__box__register-button">
                        <button className="register__box__register-button__style">Edit Cryptocurrency</button>
                    </div>
                </div>
            </form>
        );
    }
};

export default EditCryptocurrency;
