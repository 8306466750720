import React from 'react';
import moment from 'moment';

class RecentActivityItem extends React.Component {
    
    render () {
        return(
            <div className="list-item">
                <div style= {{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <div>
                        <h3 className="list-item__title">Transaction {this.props.status === 'completed' ? 'Complete' : 'Pending'}</h3>
                        <div className="list-item__notification-date">{moment.unix(this.props.date).format('DD/MM/YYYY')}</div>
                        <div className="list-item__notification-date">{moment.unix(this.props.date).format('HH:mm')}</div>
                    </div>
                    <div>
                        {
                            this.props.type === 'received' ?
                            <h3 className="list-item__title" style={{ color: 'green' }}>+ {this.props.amount + ' ' + this.props.crypto_name}</h3> :
                            <h3 className="list-item__title" style={{ color: '#990000' }}>- {this.props.amount + ' ' + this.props.crypto_name}</h3>
                        }
                    </div>
                </div>
            </div>
        );
    };
};

export default RecentActivityItem;
