import React from 'react';
import Modal from 'react-modal';
import PinInput from 'react-pin-input';
import validator from 'validator';
import { FaCoins, FaPhone } from "react-icons/fa";

import { auth } from '../../services/api';
import { transactions } from '../../services/api';

import logo from '../../assets/images/logo.png';
import sucessIcon from '../../assets/images/green_checkmark.svg';
import failIcon from '../../assets/images/fail_icon.svg';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        width: '95%',
        padding: '0px'
    }
};

const initialState = {
    errors: [],
    errorType: '',
    modalIsOpen: false,
    uncofirmed: true,
    success: false,
    pinCode: '',
    pinConfirmed: false,
    uncofirmed: true
};

const verifyPin = async (pin, callback) => {
    try {
        const res = await auth.post('auth/verify-pin', {
            pin
        });

        callback(null, res.data.result)
    } catch (err) {
        callback(err);
    }
}

Modal.setAppElement(document.getElementById('app'));

class SendPageForm extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props);

        this.state = {
            ...initialState,
            selected: '',
            transaction_cost: '',
            phoneNumber: '',
            amount: ''
        };
    };

    resetState = () => {
        this.setState(initialState);
    }

    openModal = () => {
        this.setState(() => ({ modalIsOpen: true }));
    };

    afterOpenModal = () => {
    }

    closeModal = () => {
        if (this.state.success) {
            this.setState(() => ({ modalIsOpen: false  }));
            this.props.history.push('/dashboard');
        } else {
            this.setState(() => ({ modalIsOpen: false  }));
            this.resetState();
        } 
    }

    validateFields = () => {
        this.setState(() => ({errors: [], errorType: ''}));

        if (!validator.isDecimal(this.state.amount, { decimal_digits: '0,2' })) {
            this.setState(() => ({errors: ['You must enter a valid amount'], errorType: 'amount'}));
            return false;
        }

        if (!validator.isMobilePhone(this.state.phoneNumber, 'pt-PT')) {
            this.setState(() => ({errors: ['You must enter a valid phone number from Portugal'], errorType: 'phone_number'}));
            return false;
        }

        if (validator.isEmpty(this.state.selected)) {
            this.setState(() => ({errors: ['You must choose one cryptocurrency'], errorType: 'selected'}));
            return false;
        }

        return true;
    }

    completeTransaction = async () => {
        if (this.validateFields()) {
            try {
                const res = await transactions.post('transactions/send-crypto', {
                    phone_number: this.state.phoneNumber,
                    amount: this.state.amount,
                    transaction_cost: this.state.transaction_cost,
                    crypto: this.state.selected
                });

                return true;
            } catch (err) {
                this.setState(() => ({errors: [err.response.data.errors[0].message], errorType: ''}));

                return false;
            }
        }
    }

    onSubmit = async (e) => {
        e.preventDefault();

        this.openModal();
    };

    onPhoneNumberChange = (e) => {
        const phoneNumber = e.target.value;

        this.setState(() => ({ phoneNumber }));
    };

    onAmountChange = (e) => {
        const amount = e.target.value;

        if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
            this.setState(() => ({ amount }));
        }
    };

    onSelectChange = (e) => {
        const { value } = e.target;

        this.setState({ selected: value.split(':')[0], transaction_cost: value.split(':')[1] });
    };

    onPinCodeChange = (pinCode) => {
        this.setState(() => ({ pinCode }))
    };

    confirmScan = () => {
        this.setState({ uncofirmed: false });
    }

    declineScan = () => {
        this.closeModal();
        this.props.history.push('/dashboard');
    }

    checkCode = (value, index) => {
        verifyPin(value ,(error, result) => {
            if (error) {
                this.setState({ errors: [error.response.data.errors[0].message] });
                this.setState({ pinConfirmed: false });
            }

            if (result) {
                const transactionResult = this.completeTransaction();

                transactionResult.then((res) => {
                    if (res) {
                        this.setState({ success: true });
                    } else {
                        this.setState({ success: false });
                    }
                    this.setState({ pinConfirmed: true });
                });
            } else {
                this.setState({ errors: 'Wrong PIN. Try again!' });
                this.setState({ pinConfirmed: true });
                this.setState({ success: false });
            }
        });
    };

    render() {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Info Modal"
                >
                    <div className="pay-page-modal">
                    {
                        this.state.uncofirmed ? 
                        <div>
                            <h2 ref={_subtitle => (this.state.subtitle = _subtitle)} style={{ margin: 0, marginTop: '5px' }}>Transaction Confirmation</h2>
                            <div>
                                <h3 style={{ textAlign: 'center', margin: '0px', marginBottom: '10px' }}>Transaction Details</h3>
                                <div><span style={{ fontWeight: 'bold' }}>Amount:</span> {this.state.amount} {this.state.selected}</div>
                                <div><span style={{ fontWeight: 'bold' }}>Fee:</span> {this.state.amount * this.state.transaction_cost} {this.state.selected}</div>
                                <div><span style={{ fontWeight: 'bold' }}>Total:</span> {Number.parseFloat(this.state.amount) + Number.parseFloat(this.state.amount * this.state.transaction_cost)} {this.state.selected}</div>
                                <div><span style={{ fontWeight: 'bold' }}>From:</span> You</div>
                                <div><span style={{ fontWeight: 'bold' }}>To:</span> {this.state.phoneNumber}</div>
                            </div>
                            <div className="modal-buttons" style={{ marginBottom: '5px' }}>
                                <button className="modal-buttons__decline" onClick={this.declineScan}>Decline</button>
                                <button className="modal-buttons__confirm" onClick={this.confirmScan}>Confirm</button>
                            </div>
                        </div> : <div>
                            {
                                !this.state.pinConfirmed ?
                                <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <img src={logo} style={{ height: '125px', marginTop: '5px' }} />
                                    <h2 ref={_subtitle => (this.state.subtitle = _subtitle)} style={{ margin: 0 }}>Enter your PIN</h2>
                                    <PinInput
                                        type="numeric"
                                        style={{ marginBottom: '5px' }}
                                        length={6}
                                        focus
                                        inputMode="number"
                                        value={this.state.pinCode}
                                        onChange={this.onPinCodeChange}
                                        onComplete={this.checkCode}
                                    />
                                </div> :
                                this.state.success ? 
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>   
                                    <img src={sucessIcon} alt="Success Icon" width="150px" height="150px" />
                                    <h3 style={{ textAlign: 'center', margin: '0px', marginBottom: '10px', fontWeight: 'bold' }}>Transaction Complete</h3>
                                </div> : 
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>   
                                    <img src={failIcon} alt="Fail Icon" width="150px" height="150px" />
                                    <h3 style={{ textAlign: 'center', margin: '0px', marginBottom: '10px', fontWeight: 'bold' }}>Transaction Failed</h3>
                                    <h2 style={{ textAlign: 'center' }}>{this.state.errors}</h2>
                                </div>
                            }
                        </div>
                    }
                    </div>
                </Modal>
                <form className="send-page__form" onSubmit={this.onSubmit}>
                    <div className="send-page__inputs-container">
                        <div className="input-box">
                            <FaPhone />
                            <input style={{ border: 'none', backgroundColor: '#e3e3e3' }} type="text" placeholder="Phone Number" value={this.state.phoneNumber} onChange={this.onPhoneNumberChange} />
                        </div>
                        <div className="input-box">
                            <FaCoins />
                            <input
                            style={{ border: 'none', backgroundColor: '#e3e3e3' }}
                            type="text"
                            placeholder="Amount"
                            value={this.state.amount}
                            onChange={this.onAmountChange}
                            />
                        </div>
                        <select className="send-page__select" name="cryptos" id="cryptos" onChange={this.onSelectChange} >
                        <option key={''} value={''}>{}</option>
                            {
                                this.props.cryptos.map((crypto) => {
                                    return <option key={crypto.name} value={crypto.name + ':' + crypto.transaction_cost}>{crypto.symbol + ' ' + crypto.name}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="register__error-warning">
                            {this.state.errors.length > 0 ? this.state.errors : ''}
                    </div>
                    <div>
                        <button className="send-page__button">Send</button>
                    </div>
                </form>
            </div>
        );
    }
};

export default SendPageForm;
