import React from 'react';
import ReactCodeInput from 'react-code-input';
import logo from '../../assets/images/logo_example.png';
import InitialHeader from '../InitialHeader';

class PinCodePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pinCode: ''
        }
    }

    onPinCodeChange = (pinCode) => {
        this.setState(() => ({ pinCode }))
    }

    render () {
        return (
            <div>
                <InitialHeader title="PIN" history={this.props.history} />
                <div className="pin-code-page">
                    <img src={logo}  className="password-recovery__img" />
                    <h2>Enter your PIN</h2>
                    <ReactCodeInput
                        type="password"
                        fields={6}
                        value={this.state.pinCode}
                        onChange={this.onPinCodeChange}
                    />
                    <div>
                        <button className="pin-code__button-forgot">Forgot your PIN? Recover here.</button>
                    </div>
                </div>
            </div>
        );
    }
};

export default PinCodePage;