import React from 'react';
import validator from 'validator';
import { FaRegEnvelope, FaCoins } from "react-icons/fa";

import { auth } from '../../services/api';

class AddUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            initial_amount: '',
            errors: [],
            errorType: ''
        };
    };

    onEmailChange = (e) => {
        const email = e.target.value;

        this.setState(() => ({ email }));
    }

    onInitialAmountChange = (e) => {
        const initial_amount = e.target.value;

        this.setState(() => ({ initial_amount }));
    }

    validateFields = () => {
        this.setState(() => ({errors: [], errorType: ''}));

        if (!validator.isEmail(this.state.email)) {
            this.setState(() => ({errors: ['You must enter a valid email'], errorType: 'email'}));
            return false;
        }
        if (!validator.matches(this.state.initial_amount, /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/)) {
            this.setState(() => ({errors: ['You must enter a valid initial amount (decimal number)'], errorType: 'symbol'}));
            return false;
        }

        return true;
    }

    onSubmit = async (e) => {
        e.preventDefault();

        if (this.validateFields()) {
            try {
                const res = await auth.post('auth/admin/invite-user', {
                    email: this.state.email,
                    initial_amount: this.state.initial_amount
                });
    
                this.props.history.push('/admin-panel');
            } catch (err) {
                this.setState(() => ({errors: [err.response.data.errors[0].message], errorType: ''}));
            }
        }
    }

    render() {
        return (
            <form className="register__box" style={{ marginTop: '80px' }} onSubmit={this.onSubmit}>
                <div className="register__box__form" style={{ paddingBottom: '25px' }}>
                    {
                        this.state.errorType === 'email'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }}>
                            <FaRegEnvelope />
                            <input className="register__text-area" type="text" placeholder="Email" value={this.state.email} onChange={this.onEmailChange} />
                        </div>
                        :
                        <div className="input-box">
                            <FaRegEnvelope />
                            <input className="register__text-area" type="text" placeholder="Email" value={this.state.email} onChange={this.onEmailChange} />
                        </div>
                    }
                    {
                        this.state.errorType === 'initial_amount'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }}>
                            <FaCoins />
                            <input className="register__text-area" type="text" placeholder="Initial Amount" value={this.state.initial_amount} onChange={this.onInitialAmountChange} />
                        </div>
                        :
                        <div className="input-box">
                            <FaCoins />
                            <input className="register__text-area" type="text" placeholder="Initial Amount" value={this.state.initial_amount} onChange={this.onInitialAmountChange} />
                        </div>
                    }
                </div>
                <div className="register__error-warning">
                        {this.state.errors.length > 0 ? this.state.errors : ''}
                </div>
                <div>
                    <div className="register__box__register-button">
                        <button className="register__box__register-button__style">Invite User</button>
                    </div>
                </div>
            </form>
        );
    }
};

export default AddUser;