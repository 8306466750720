import React from 'react';
import moment from 'moment';

import { FaInfoCircle } from "react-icons/fa";

class OrderPageItem extends React.Component {
    constructor(props) {
        super(props);
    }

    onClickInfo = () => {
        if (this.props.side === 'SELL') {
            alert(`This sell order was one of the last orders executed in the market. The information displayed is based on the privacy of the cryptocurrency:\n-private: Will not display any orders;\n-semi-private: Will display only the ID of an user that can't be mapped to a real person;\n-public: Will display the name of the user that created the order.`);
        } else {
            alert(`This buy order was one of the last orders executed in the market. The information displayed is based on the privacy of the cryptocurrency:\n-private: Will not display any orders;\n-semi-private: Will display only the ID of an user that can't be mapped to a real person;\n-public: Will display the name of the user that created the order.`);
        }
    }

    render () {
        return(
            <div className="portfolio__list-item">
                <div className="portfolio__list-item__body" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <div className="portfolio__list-item__body-title">Transaction of {this.props.quantity} {this.props.symbol} at {this.props.price} € each</div>
                    <div>
                        <div onClick={this.onClickInfo}><FaInfoCircle style={{ color: 'blue' }}/></div>
                    </div>
                </div>
                <div className="portfolio__list-item__body">
                    {
                        this.props.privacy === 'public' ? 
                        <div className="portfolio__list-item__body-subtitle"><span style={{ fontWeight: 'bold' }}>Traders Names:</span> {this.props.full_name} <span style={{ fontSize: '14px' }}>&#10132;</span> {this.props.full_name_opposite}</div> : 
                        <div className="portfolio__list-item__body-subtitle"><span style={{ fontWeight: 'bold' }}>Traders IDs:</span> {this.props.trader_id} <span style={{ fontSize: '14px' }}>&#10132;</span> {this.props.opposite_trader_id}</div>
                    }
                </div>
                <div className="portfolio__list-item__body-profit-loss">
                    <div className="portfolio__list-item__body__date">{moment.unix(this.props.date).format('D MMM YYYY HH:mm')}</div>
                </div>
            </div>
        );
    };
};

export default OrderPageItem;
