import React from 'react';
import Modal from 'react-modal';
import PinInput from 'react-pin-input';
import moment from 'moment';

import { OrderBuyPageForm, OrderSellPageForm } from './OrderPageForm';

import logo from '../../assets/images/logo.png';
import sucessIcon from '../../assets/images/green_checkmark.svg';
import failIcon from '../../assets/images/fail_icon.svg';

import { auth } from '../../services/api';
import { market } from '../../services/api';

import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import OrderPageItem from './OrderPageItem';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        width: '95%',
        padding: '0px'
    }
};

const initialState = {
    pinCode: '',
    subtitle: '',
    modalIsOpen: false,
    uncofirmed: true,
    success: false,
    pinConfirmed: false,
    cryptos: [],
    userBalance: '',
    buyPrice: '',
    sellPrice: ''
};

Modal.setAppElement(document.getElementById('app'));

const verifyPin = async (pin, callback) => {
    try {
        const res = await auth.post('auth/verify-pin', {
            pin
        });

        callback(null, res.data.result)
    } catch (err) {
        callback(err);
    }
}

export class OrderBuyPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...initialState,
            cryptoSelected: '',
            priceToBuy: '', 
            amount: '',
            side: '',
            date: '',
            cryptoCurrentlySelected: '',
            orderbook_bids: [],
            orderbook_bids_display: [],
            orderbook_bids_loaded: false
        };
    }

    componentDidMount() {
        this.getCryptosAvailable();
        this.getLastBids();
    }

    getCryptosAvailable = async () => {
        try {
            const res = await market.get('market/get-cryptos-buy');
            this.setState({ cryptos: res.data.result, userBalance: res.data.userBalance[0].balance });
            this.setState({ dataLoaded: true });
        } catch (err) {
            console.error(err);
        }
    }

    getLastBids = async () => {
        try {
            const res = await market.get('market/get-last-executed-orders');

            this.setState({ orderbook_bids: res.data.result });
            this.setState({ orderbook_bids_loaded: true });
        } catch (err) {
            console.error(err);
        }
    }

    resetState = () => {
        this.setState(initialState);
    }

    openModal = () => {
        this.setState(() => ({ modalIsOpen: true }));
    };

    afterOpenModal = () => {
        this.state.subtitle.style.color = '#000000';
    }

    closeModal = () => {
        this.setState(() => ({ modalIsOpen: false  }));
        this.resetState();
        this.props.history.push('/dashboard');
    }

    onPinCodeChange = (pinCode) => {
        this.setState(() => ({ pinCode }))
    }

    confirmTrade = () => {
        this.setState({ uncofirmed: false });
    }

    declineTrade = () => {
        this.closeModal();
        this.props.history.push('/dashboard');
    }

    completeOrder = async () => {
        if (this.state.side === 'limit') {
            try {
                const res = await market.post('market/add-limit-order', {
                    crypto: this.state.cryptoSelected,
                    price: this.state.priceToBuy,
                    quantity: this.state.amount,
                    side: 'BUY',
                    cancelled: this.state.date === '' ? 'None' : moment(this.state.date).unix()
                });

                return true;
            } catch (err) {
                this.setState(() => ({errors: [err.response.data.errors[0].message], errorType: ''}));

                return false;
            }
        } else {
            try {
                const res = await market.post('market/add-market-order', {
                    crypto: this.state.cryptoSelected,
                    quantity: this.state.amount,
                    side: 'BUY',
                    cancelled: this.state.date === '' ? 'None' : moment(this.state.date).unix()
                });

                return true;
            } catch (err) {
                this.setState(() => ({errors: [err.response.data.errors[0].message], errorType: ''}));

                return false;
            }
        }
    }

    checkCode = (value, index) => {
        verifyPin(value ,(error, result) => {
            if (error) {
                this.setState({ errors: [error.response.data.errors[0].message] });
                this.setState({ pinConfirmed: false });
            }

            if (result) {
                const orderResult = this.completeOrder();

                orderResult.then((res) => {
                    if (res) {
                        this.setState({ success: true });
                    } else {
                        this.setState({ success: false });
                    }
                    this.setState({ pinConfirmed: true });
                });
            } else {
                this.setState({ errors: 'Wrong PIN. Try again!' });
                this.setState({ pinConfirmed: true });
                this.setState({ success: false });
            }
        });
    }

    onSubmit = (cryptoSelected, priceToBuy, amount, side, date) => {
        if (date === undefined) {
            this.setState({ cryptoSelected, priceToBuy, amount, side });
        } else {
            this.setState({ cryptoSelected, priceToBuy, amount, side, date });
        }
        
        this.openModal();
    };

    onClickSell = () => {
        this.props.history.push('/order-sell');
    }

    changePrice = (buyPrice, sellPrice, cryptoCurrentlySelected) => {
        let orderbook_bids_display = this.state.orderbook_bids.filter((el) => {
            if (el.crypto_id.toString() === cryptoCurrentlySelected) {
                return el;
            }
        });

        this.setState({ buyPrice, sellPrice, cryptoCurrentlySelected, orderbook_bids_display })
    }

    render () {
        return (
            <div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="QR Code Modal"
                >
                    <div className="pay-page-modal">
                    {
                        this.state.uncofirmed ? 
                        <div>
                            <h2 ref={_subtitle => (this.state.subtitle = _subtitle)} style={{ margin: 0, marginTop: '5px' }}>Order Confirmation</h2>
                            <div>
                                <h3 style={{ textAlign: 'center', margin: '0px', marginBottom: '10px' }}>Buy Order Details</h3>
                                <div><span style={{ fontWeight: 'bold' }}>Amount:</span> {this.state.amount}</div>
                                {this.state.side === 'limit' && <div><span style={{ fontWeight: 'bold' }}>Price:</span> {Number.parseFloat(this.state.priceToBuy)} </div>}
                                {this.state.date !== '' && <div><span style={{ fontWeight: 'bold' }}>Good Until:</span> {this.state.date.toLocaleDateString("pt-PT")}</div>}                          </div>
                            <div className="modal-buttons" style={{ marginBottom: '5px' }}>
                                <button className="modal-buttons__decline" id="decline_button" onClick={this.declineTrade}>Decline</button>
                                <button className="modal-buttons__confirm" id="confirm_button" onClick={this.confirmTrade}>Confirm</button>
                            </div>
                        </div> : 
                        <div>
                        {
                            !this.state.pinConfirmed ?
                            <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <img src={logo} style={{ height: '125px', marginTop: '5px' }}/>
                                <h2 ref={_subtitle => (this.state.subtitle = _subtitle)} style={{ margin: 0 }}>Enter your PIN</h2>
                                <PinInput
                                    type="numeric"
                                    style={{ marginBottom: '5px' }}
                                    length={6}
                                    focus
                                    inputMode="number"
                                    value={this.state.pinCode}
                                    onChange={this.onPinCodeChange}
                                    onComplete={this.checkCode}
                                />
                            </div> :
                            <div>
                            {
                                this.state.success ? 
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>   
                                    <img src={sucessIcon} alt="Success Icon" width="150px" height="150px" />
                                    <h3 id="h3_success" style={{ textAlign: 'center', margin: '0px', marginBottom: '10px', fontWeight: 'bold' }}>Order Created Successfully</h3>
                                </div> : 
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>   
                                    <img src={failIcon} alt="Fail Icon" width="150px" height="150px" />
                                    <h3 id="h3_failed" style={{ textAlign: 'center', margin: '0px', marginBottom: '10px', fontWeight: 'bold' }}>Order Failed</h3>
                                    <h2 id="h2_error">{this.state.errors}</h2>
                                </div>
                            }
                            </div>
                        }
                        </div>
                    }
                    </div>
                </Modal>
                {
                    this.state.dataLoaded && 
                    <div className="trade-order__page" style={{ height: '90vh' }}>
                        <div className="trade-order__header">
                            <button className="trade-order__header__button-inactive" onClick={this.onClickSell}>
                                <div style={{ paddingLeft: '5px', fontWeight: 'bold' }}>Sell</div>
                                <div style={{ paddingRight: '5px', fontWeight: 'bold' }}>{this.state.sellPrice === 'null' ? 'N/A €' : `${this.state.sellPrice} €`}</div>
                            </button>
                            <button className="trade-order__header__button-buy-active">
                                <div style={{ paddingLeft: '5px', fontWeight: 'bold' }}>Buy</div>
                                <div style={{ paddingRight: '5px', fontWeight: 'bold' }}>{this.state.buyPrice === 'null' ? 'N/A €' : `${this.state.buyPrice} €`}</div>
                            </button>
                        </div>
                        <OrderBuyPageForm cryptos={this.state.cryptos} userBalance={this.state.userBalance} changePrice={this.changePrice} onSubmit={this.onSubmit}/>
                        <div className="content-container-trade">
                            <div className="list-header">
                                <div className="show-for-mobile"><span className="list-item__body-title">Orders History</span></div>
                                <div className="show-for-desktop"><span className="list-item__body-title">Orders History</span></div>
                            </div>
                            <div className="list-body">
                            {
                                this.state.orderbook_bids_loaded && (
                                    this.state.orderbook_bids_display.length === 0 ? (
                                    <div className="list-item list-item--message">
                                        <span style={{ textAlign: 'center' }}>There are no sell orders in the history or this cryptocurrency privacy is private.</span>
                                    </div>
                                    ) : (
                                        this.state.orderbook_bids_display.map((bid) => {
                                            return <OrderPageItem key={bid.order_id} {...bid} />;
                                        })
                                    )
                                )
                            }
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
};

//<FooterSecondaryTradeOrder titleOne="Trade" titleSecond="Order" {...this.props} />

export class OrderSellPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...initialState,
            cryptoSelected: '',
            priceToSell: '', 
            amount: '',
            side: '',
            date: '',
            cryptoCurrentlySelected: '',
            orderbook_asks: [],
            orderbook_asks_display: [],
            orderbook_asks_loaded: false
        };
    }

    componentDidMount() {
        this.getCryptosAvailable();
        this.getLastAsks();
    }

    getCryptosAvailable = async () => {
        try {
            const res = await market.get('market/get-cryptos-sell');
            this.setState({ cryptos: res.data.result });
            this.setState({ dataLoaded: true });
        } catch (err) {
            console.error(err);
        }
    }

    getLastAsks = async () => {
        try {
            const res = await market.get('market/get-last-executed-orders');
            this.setState({ orderbook_asks: res.data.result });
            this.setState({ orderbook_asks_loaded: true });
        } catch (err) {
            console.error(err);
        }
    }

    resetState = () => {
        this.setState(initialState);
    }

    openModal = () => {
        this.setState(() => ({ modalIsOpen: true }));
    };

    afterOpenModal = () => {
        this.state.subtitle.style.color = '#000000';
    }

    closeModal = () => {
        this.setState(() => ({ modalIsOpen: false  }));
        this.resetState();
        this.props.history.push('/dashboard');
    }

    onPinCodeChange = (pinCode) => {
        this.setState(() => ({ pinCode }))
    }

    confirmTrade = () => {
        this.setState({ uncofirmed: false });
    }

    declineTrade = () => {
        this.closeModal();
        this.props.history.push('/dashboard');
    }

    completeOrder = async () => {
        if (this.state.side === 'limit') {
            try {
                const res = await market.post('market/add-limit-order', {
                    crypto: this.state.cryptoSelected,
                    price: this.state.priceToSell,
                    quantity: this.state.amount,
                    side: 'SELL',
                    cancelled: this.state.date === '' ? 'None' : moment(this.state.date).unix()
                });

                return true;
            } catch (err) {
                this.setState(() => ({errors: [err.response.data.errors[0].message], errorType: ''}));

                return false;
            }
        } else {
            try {
                const res = await market.post('market/add-market-order', {
                    crypto: this.state.cryptoSelected,
                    quantity: this.state.amount,
                    side: 'SELL',
                    cancelled: this.state.date === '' ? 'None' : moment(this.state.date).unix()
                });

                return true;
            } catch (err) {
                console.error(err);
                this.setState(() => ({errors: [err.response.data.errors[0].message], errorType: ''}));

                return false;
            }
        }
    }

    checkCode = (value, index) => {
        verifyPin(value ,(error, result) => {
            if (error) {
                this.setState({ errors: [error.response.data.errors[0].message] });
                this.setState({ pinConfirmed: false });
            }
            if (result) {
                const orderResult = this.completeOrder();

                orderResult.then((res) => {
                    if (res) {
                        this.setState({ success: true });
                    } else {
                        this.setState({ success: false });
                    }
                    this.setState({ pinConfirmed: true });
                });
            } else {
                this.setState({ errors: 'Wrong PIN. Try again!' });
                this.setState({ pinConfirmed: true });
                this.setState({ success: false });
            }
        });
    }

    onSubmit = (cryptoSelected, priceToSell, amount, side, date) => {
        if (date === undefined) {
            this.setState({ cryptoSelected, priceToSell, amount, side });
        } else {
            this.setState({ cryptoSelected, priceToSell, amount, side, date });
        }
        
        this.openModal();
    };

    onClickBuy = () => {
        this.props.history.push('/order-buy');
    }
    
    changePrice = (buyPrice, sellPrice, cryptoCurrentlySelected) => {
        let orderbook_asks_display = this.state.orderbook_asks.filter((el) => {
            if (el.crypto_id.toString() === cryptoCurrentlySelected) {
                return el;
            }
        });
        this.setState({ buyPrice, sellPrice, cryptoCurrentlySelected, orderbook_asks_display })
    }

    render () {
        return (
            <div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="QR Code Modal"
                >
                    <div className="pay-page-modal">
                    {
                        this.state.uncofirmed ? 
                        <div>
                            <h2 ref={_subtitle => (this.state.subtitle = _subtitle)} style={{ margin: 0, marginTop: '5px' }}>Order Confirmation</h2>
                            <div>
                                <h3 style={{ textAlign: 'center', margin: '0px', marginBottom: '10px' }}>Sell Order Details</h3>
                                <div><span style={{ fontWeight: 'bold' }}>Amount:</span> {this.state.amount}</div>
                                {this.state.side === 'limit' && <div><span style={{ fontWeight: 'bold' }}>Price:</span> {Number.parseFloat(this.state.priceToSell)} </div>}
                                {this.state.date !== '' && <div><span style={{ fontWeight: 'bold' }}>Good Until:</span> {this.state.date.toLocaleDateString("pt-PT")}</div>}                          </div>
                            <div className="modal-buttons" style={{ marginBottom: '5px' }}>
                                <button className="modal-buttons__decline" id="decline_button" onClick={this.declineTrade}>Decline</button>
                                <button className="modal-buttons__confirm" id="confirm_button" onClick={this.confirmTrade}>Confirm</button>
                            </div>
                        </div> : 
                        <div>
                        {
                            !this.state.pinConfirmed ?
                            <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <img src={logo} style={{ height: '125px', marginTop: '5px' }}/>
                                <h2 ref={_subtitle => (this.state.subtitle = _subtitle)} style={{ margin: 0 }}>Enter your PIN</h2>
                                <PinInput
                                    type="numeric"
                                    style={{ marginBottom: '5px' }}
                                    length={6}
                                    focus
                                    inputMode="number"
                                    value={this.state.pinCode}
                                    onChange={this.onPinCodeChange}
                                    onComplete={this.checkCode}
                                />
                            </div> :
                            <div>
                            {
                                this.state.success ? 
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>   
                                    <img src={sucessIcon} alt="Success Icon" width="150px" height="150px" />
                                    <h3 id="h3_success" style={{ textAlign: 'center', margin: '0px', marginBottom: '10px', fontWeight: 'bold' }}>Order Created Successfully</h3>
                                </div> : 
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>   
                                    <img src={failIcon} alt="Fail Icon" width="150px" height="150px" />
                                    <h3 id="h3_failed" style={{ textAlign: 'center', margin: '0px', marginBottom: '10px', fontWeight: 'bold' }}>Order Failed</h3>
                                    <h2 id="h2_error">{this.state.errors}</h2>
                                </div>
                            }
                            </div>
                        }
                        </div>
                    }
                    </div>
                </Modal>
                {
                    this.state.dataLoaded &&
                    <div className="trade-order__page" style={{ height: '90vh' }}>
                        <div className="trade-order__header">
                            <button className="trade-order__header__button-sell-active">
                                <div style={{ paddingLeft: '5px', fontWeight: 'bold' }}>Sell</div>
                                <div style={{ paddingRight: '5px', fontWeight: 'bold' }}>{this.state.sellPrice === 'null' ? 'N/A €' : `${this.state.sellPrice} €`}</div>
                            </button>
                            <button className="trade-order__header__button-inactive" onClick={this.onClickBuy}>
                                <div style={{ paddingLeft: '5px', fontWeight: 'bold' }}>Buy</div>
                                <div style={{ paddingRight: '5px', fontWeight: 'bold' }}>{this.state.buyPrice === 'null' ? 'N/A €' : `${this.state.buyPrice} €`}</div>
                            </button>
                        </div>
                        <OrderSellPageForm cryptos={this.state.cryptos} changePrice={this.changePrice} onSubmit={this.onSubmit} />
                        <div className="content-container-trade">
                            <div className="list-header">
                                <div className="show-for-mobile"><span className="list-item__body-title">Orders History</span></div>
                                <div className="show-for-desktop"><span className="list-item__body-title">Orders History</span></div>
                            </div>
                            <div className="list-body">
                            {
                                this.state.orderbook_asks_loaded && (
                                    this.state.orderbook_asks_display.length === 0 ? (
                                    <div className="list-item list-item--message">
                                        <span style={{ textAlign: 'center' }}>There are no sell orders in the history or this cryptocurrency privacy is private.</span>
                                    </div>
                                    ) : (
                                        this.state.orderbook_asks_display.map((ask) => {
                                            return <OrderPageItem key={ask.order_id} {...ask} />;
                                        })
                                    )
                                )
                            }
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
};
