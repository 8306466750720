import React from 'react';
import { FaFilter } from 'react-icons/fa';
import CryptoListItem from './CryptoListItem';

class CryptoList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cryptosFilteredList: [],
            filtersLoaded: false
        };
    };

    componentDidMount() {
        this.buildCrytoListFilters();
    }

    buildCrytoListFilters = async () => {
        await this.props.cryptosFilter.forEach((crypto) => {
            this.setState((previousState) => ({ cryptosFilteredList:  previousState.cryptosFilteredList.concat({name: crypto.name, checked: !!crypto.selected})}));
            // cryptosFilteredList.push({name: crypto.name, checked: crypto.selected})
        });

        this.setState({ filtersLoaded: true });
    }

    openFilters = () => {
        this.props.history.push('/filters');
    }

    checkFilteredList = () => {
        if (this.state.cryptosFilteredList.filter(el => el.checked === true).length > 0) {
            return false;
        }

        return true;
    }

    render () {
        return(
            <div className="content-container">
                <div className="list-header">
                    <div className="show-for-mobile"><span className="list-item__body-title">Wallet</span></div>
                    <div className="show-for-mobile"><button className="list-item__button-activity list-item__button-activity--filter" onClick={this.openFilters}><FaFilter /> Filter</button></div>
                    <div className="show-for-desktop"><span className="list-item__body-title">Wallet</span></div>
                    <div className="show-for-desktop"><button className="list-item__button-activity list-item__button-activity--filter" onClick={this.openFilters}><FaFilter /> Filter</button></div>
                </div>
                <div className="list-body">
                {
                    this.state.filtersLoaded && (this.checkFilteredList() ? (
                    <div className="list-item list-item--message">
                        <span>All cryptos are hidden</span>
                    </div>
                    ) : (
                        this.props.cryptos.filter((crypto) => {
                            let elIndex = this.state.cryptosFilteredList.findIndex((el => el.name === crypto.name))

                            return this.state.cryptosFilteredList[elIndex].checked;
                        }).map((crypto) => {
                            return <CryptoListItem key={crypto.name} phase={this.props.phase} {...crypto} />;
                        })
                    ))
                }
                </div>
            </div>
        );
    };
};

export default CryptoList;
