import React from 'react';

import loaderGif from '../assets/loader.gif';

const LoadingPage = () => (
    <div className="loader">
        <img className="loader__image" src={loaderGif} />
    </div>
);

export default LoadingPage; 
