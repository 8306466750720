import React from 'react';
import Modal from 'react-modal';
import QRCode from 'qrcode.react';
import RequestPageForm from './RequestPageForm';

import { transactions } from '../../services/api';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

Modal.setAppElement(document.getElementById('app'));

class RequestPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subtitle: '',
            modalIsOpen: false,
            walletAddress: '',
            cryptos: [],
            dataLoaded: false,
            qrCodeValue: ''
        };
    }

    componentDidMount() {
        this.getCryptosAvailable();
    }

    getCryptosAvailable = async () => {
        try {
            const res = await transactions.get('transactions/get-cryptos');

            console.log(res.data.result)

            this.setState({ cryptos: [{
                name: 'Euros',
                symbol: '€'
            }].concat(res.data.result) });
            this.setState({ walletAddress: res.data.wallet_address });
            this.setState({ dataLoaded: true });
        } catch (err) {
            console.log(err);
        }
    }

    openModal = () => {
        this.setState(() => ({ modalIsOpen: true }));
    };

    afterOpenModal = () => {
        this.state.subtitle.style.color = '#000000';
    }

    closeModal = () => {
        this.setState(() => ({ modalIsOpen: false  }));
    }

    onSubmit = (amount, crypto) => {
        this.setState({ qrCodeValue: `${crypto}:${this.state.walletAddress}?amount=${amount}` })
        this.openModal();
    };

    render () {
        return (
            <div>
                {
                    this.state.dataLoaded && (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <RequestPageForm cryptos={this.state.cryptos} onSubmit={this.onSubmit} />
                            <Modal
                                isOpen={this.state.modalIsOpen}
                                onAfterOpen={this.afterOpenModal}
                                onRequestClose={this.closeModal}
                                style={customStyles}
                                contentLabel="QR Code Modal"
                            >
                                <div className="request-page__modal">
                                    <h3 id="h3_qr_code" ref={_subtitle => (this.state.subtitle = _subtitle)}>Scan this QR Code</h3>
                                    <QRCode
                                        id={this.state.walletAddress}
                                        name="qr_code"
                                        value={this.state.qrCodeValue}
                                        size={250}
                                        level={"H"}
                                        includeMargin={true}
                                    />
                                    <button className="request-page__button" onClick={this.closeModal}>Done</button>
                                </div>
                            </Modal>
                        </div>
                    )
                }
            </div>
        );
    }
};

export default RequestPage;
