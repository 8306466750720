import React from 'react';
import InitialHeader from '../InitialHeader';
import ForgotPasswordForm from './ForgotPasswordForm';
import logo from '../../assets/images/logo.png';

class ForgotPasswordPage extends React.Component {

    onSubmit = (id_user) => {
        this.props.history.push(`/reset-password/${id_user}`);
    };

    render () {
        console.log(this.props)
        return (
            <div className="password-recovery-page">
                <InitialHeader title="Forgot Password?" history={this.props.history} />
                <div className="password-recovery">
                    <img src={logo}  className="password-recovery__img" style={{ marginTop: '70px', width: '125px' }} />
                    <h3 className="password-recovery__title" style={{ margin: 0 }}>Enter your email or phone number an email with a link to reset will be sent to you.</h3>
                    <ForgotPasswordForm onSubmit={this.onSubmit}/>
                </div>    
            </div>
        );
    }
}

export default ForgotPasswordPage;
