import React, { useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import LoginPage from '../components/Login/LoginPage';
import NotFoundPage from '../components/NotFoundPage';
import PrivateRoute from './PrivateRoute.js';
import PublicRoute from './PublicRoute';
import ForgotPasswordPage from '../components/PasswordRecovery/ForgotPasswordPage';
import ResetPasswordPage from '../components/PasswordRecovery/ResetPasswordPage';
import MainMenuPage from '../components/MainMenu/MainMenuPage';
import PayPage from '../components/PaySendMenu/PayPage';
import SendPage from '../components/PaySendMenu/SendPage';
import RequestPage from '../components/RequestMenu/RequestPage';
import { TradeSellPage, TradeBuyPage } from '../components/TradeOrderMenu/TradePage';
import { OrderBuyPage, OrderSellPage } from '../components/TradeOrderMenu/OrderPage';
import { PortfolioOrdersOpenPage, PortfolioOrdersClosedPage } from '../components/PortfolioMenu/PortfolioOrdersPage';
import { PortfolioPositionsOpenPage, PortfolioPositionsClosedPage } from '../components/PortfolioMenu/PortfolioPositionsPage';
import PinCodePage from '../components/PinCodeInsertion/PinCodePage';
import SettingsPage from '../components/SettingsMenu/SettingsPage';
import NotificationsPage from '../components/NotificationsMenu/NotificationsPage';
import AllActivity from '../components/MainMenu/AllActivity';
import AboutPage from '../components/SettingsMenu/AboutPage';
import HelpPage from '../components/SettingsMenu/HelpPage';
import PermissionsPage from '../components/SettingsMenu/PermissionsPage';
import PersonalInfoPage from '../components/SettingsMenu/PersonalInfoPage';
import SecurityPage from '../components/SettingsMenu/SecurityPage';
import RegisterPage from '../components/Register/RegisterPage';
import { FiltersPage } from '../components/FiltersMainMenu/NotificationsMenu/FiltersPage';
import AdminRoute from './AdminRoute';
import AdminPanelPage from '../components/AdminPanel/AdminPanelPage';
import EditCryptocurrency from '../components/AdminPanel/EditCryptocurrency';
import AddCryptocurrency from '../components/AdminPanel/AddCryptocurrecy';
import AddUser from '../components/AdminPanel/AddUser';
import EditWalletQuantity from '../components/AdminPanel/EditWalletQuantity';
import SetAuctionTimes from '../components/AdminPanel/SetAuctionTimes';
import ContactUs from '../components/SettingsMenu/ContactUs';
import ContactUsNoLogin from '../components/ContactUsNoLogin';
import { ChangePersonalInfo, ChangePIN, ChangeWalletFilters, CheckCryptoInfo, CheckPortfolio, CheckWalletActivity, ContactLogout, LoginLogout, MakeOrder, MakePayment, RequestCrypto, ResetPassword, SendCrypto } from '../components/SettingsMenu/HelpPageTopics';

export const history = createBrowserHistory();

const AppRouter = () => {

  return(
    <Router history={history}>
      <div>
        <Switch>
          <PublicRoute path="/" component={LoginPage} exact={true} />
          <PublicRoute path="/register" component={RegisterPage} />
          <PublicRoute path="/forgot-password" component={ForgotPasswordPage} />
          <PublicRoute path="/reset-password/:id" component={ResetPasswordPage} />
          <PublicRoute path="/contact-us" component={ContactUsNoLogin} />
          <PrivateRoute path="/dashboard" component={MainMenuPage} hasHeader={true} />
          <PrivateRoute path="/filters" component={FiltersPage} hasHeader={false} />
          <PrivateRoute path="/all-activity" component={AllActivity} hasHeader={false} />
          <PrivateRoute path="/pay" component={PayPage} hasHeader={true} />
          <PrivateRoute path="/send" component={SendPage} hasHeader={true} />
          <PrivateRoute path="/request" component={RequestPage} hasHeader={true} />
          <PrivateRoute path="/order-sell" component={OrderSellPage} hasHeader={true} />
          <PrivateRoute path="/order-buy" component={OrderBuyPage} hasHeader={true} />
          <PrivateRoute path="/my-orders-open" component={PortfolioOrdersOpenPage} hasHeader={true} />
          <PrivateRoute path="/my-orders-closed" component={PortfolioOrdersClosedPage} hasHeader={true} />
          <PrivateRoute path="/pin-code" component={PinCodePage} hasHeader={false} />
          <PrivateRoute exact path="/settings" component={SettingsPage} hasHeader={false} />
          <PrivateRoute exact path="/settings/about" component={AboutPage} hasHeader={false} />
          <PrivateRoute path="/settings/about/contact-us" component={ContactUs} hasHeader={false} />
          <PrivateRoute exact path="/settings/help" component={HelpPage} hasHeader={false} />
          <PrivateRoute path="/settings/permissions" component={PermissionsPage} hasHeader={false} />
          <PrivateRoute path="/settings/personal-info" component={PersonalInfoPage} hasHeader={false} />
          <PrivateRoute path="/settings/security" component={SecurityPage} hasHeader={false} />
          <PrivateRoute path="/notifications" component={NotificationsPage} hasHeader={false} />
          <PrivateRoute path="/settings/help/manage-account/login-logout" component={LoginLogout} hasHeader={false} />
          <PrivateRoute path="/settings/help/manage-account/change-info-password" component={ChangePersonalInfo} hasHeader={false} />
          <PrivateRoute path="/settings/help/manage-account/reset-password" component={ResetPassword} hasHeader={false} />
          <PrivateRoute path="/settings/help/manage-account/change-pin" component={ChangePIN} hasHeader={false} />
          <PrivateRoute path="/settings/help/manage-account/contact-logout" component={ContactLogout} hasHeader={false} />
          <PrivateRoute path="/settings/help/wallet/change-wallet-filters" component={ChangeWalletFilters} hasHeader={false} />
          <PrivateRoute path="/settings/help/wallet/check-all-activity" component={CheckWalletActivity} hasHeader={false} />
          <PrivateRoute path="/settings/help/wallet/check-crypto-info" component={CheckCryptoInfo} hasHeader={false} />
          <PrivateRoute path="/settings/help/transactions/make-payment" component={MakePayment} hasHeader={false} />
          <PrivateRoute path="/settings/help/transactions/send-crypto" component={SendCrypto} hasHeader={false} />
          <PrivateRoute path="/settings/help/transactions/request-crypto" component={RequestCrypto} hasHeader={false} />
          <PrivateRoute path="/settings/help/market/make-order" component={MakeOrder} hasHeader={false} />
          <PrivateRoute path="/settings/help/market/check-portfolio" component={CheckPortfolio} hasHeader={false} />
          <AdminRoute exact path="/admin-panel" component={AdminPanelPage} hasHeader={true} />
          <AdminRoute path="/admin-panel/edit-crypto" component={EditCryptocurrency} hasHeader={true} />
          <AdminRoute path="/admin-panel/add-crypto" component={AddCryptocurrency} hasHeader={true} />
          <AdminRoute path="/admin-panel/add-user" component={AddUser} hasHeader={true} />
          <AdminRoute path="/admin-panel/edit-wallet-quantity" component={EditWalletQuantity} hasHeader={true} />
          <AdminRoute path="/admin-panel/set-auction-times" component={SetAuctionTimes} hasHeader={true} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    </Router>
  );
};

export default AppRouter;

/* 

          <PrivateRoute path="/portfolio-positions-open" component={PortfolioPositionsOpenPage} hasHeader={true} />
          <PrivateRoute path="/portfolio-positions-closed" component={PortfolioPositionsClosedPage} hasHeader={true} />
          <PrivateRoute path="/trade-sell" component={TradeSellPage} hasHeader={true} />
          <PrivateRoute path="/trade-buy" component={TradeBuyPage} hasHeader={true} />
*/
