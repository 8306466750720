import React from 'react';
import validator from 'validator';
import InitialHeader from './InitialHeader';
import { FaEnvelope, FaUser, FaEnvelopeOpenText } from "react-icons/fa";

import logo from '../assets/images/logo.png';

import { auth } from '../services/api';

export class ContactUsNoLogin extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            full_name: '',
            subject: '',
            message: '',
            errors: [],
            errorType: ''
        };
    }

    onEmailChange = (e) => {
        const email = e.target.value;

        this.setState(() => ({ email }));
    }

    onFullNameChange = (e) => {
        const full_name = e.target.value;

        this.setState(() => ({ full_name }));
    }

    onSubjectChange = (e) => {
        const subject = e.target.value;

        this.setState(() => ({ subject }));
    }

    onMessageChange = (e) => {
        const message = e.target.value;

        this.setState(() => ({ message }));
    }

    validateFields = () => {
        this.setState(() => ({errors: [], errorType: ''}));

        if (!validator.isEmail(this.state.email)) {
            this.setState(() => ({errors: ['You must enter a valid email address'], errorType: 'email'}));
            return false;
        }
        if (!this.state.full_name.match(/^[a-zA-Z \u00C0-\u00FF]*$/) || !validator.isLength(this.state.full_name, { min: 5 })) {
            this.setState(() => ({errors: ['You must enter a valid name with only letters and at least 5 characters'], errorType: 'full_name'}));
            return false;
        }
        if (this.state.subject.length === 0) {
            this.setState(() => ({errors: ['Subject must not be empty'], errorType: 'subject'}));
            return false;
        }
        if (this.state.message.length === 0) {
            this.setState(() => ({errors: ['Message must not be empty'], errorType: 'message'}));
            return false;
        }

        return true;
    }

    onSubmit = async (e) => {
        e.preventDefault();

        if (this.validateFields()) {
            try {
                const res = await auth.post('auth/contact-us-email', {
                    email: this.state.email,
                    full_name: this.state.full_name,
                    subject: this.state.subject,
                    message: this.state.message
                });
    
                this.props.history.push('/');
            } catch (err) {
                this.setState(() => ({errors: [err.response.data.errors[0].message], errorType: ''}));
            }
        }
    }

    render() {
        return (
            <div>
                <InitialHeader title="Contact Us" history={this.props.history} />
                <div className="content-container" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '60vh', justifyContent: 'space-between', marginTop: '60px', width: '100vw' }}>
		    <img src={logo} style={{ height: '125px' }}/>
		    {
                        this.state.errorType === 'email'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }}>
                            <FaEnvelope />
                            <input className="register__text-area" type="text" placeholder="Your Email" value={this.state.email} onChange={this.onEmailChange} />
                        </div>
                        :
                        <div className="input-box">
                            <FaEnvelope />
                            <input className="register__text-area" type="text" placeholder="Your Email" value={this.state.email} onChange={this.onEmailChange} />
                        </div>
                    }
                    {
                        this.state.errorType === 'full_name'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }}>
                            <FaUser />
                            <input className="register__text-area" type="text" placeholder="Your name" value={this.state.full_name} onChange={this.onFullNameChange} />
                        </div>
                        :
                        <div className="input-box">
                            <FaUser />
                            <input className="register__text-area" type="text" placeholder="Your name" value={this.state.full_name} onChange={this.onFullNameChange} />
                        </div>
                    }
                    {
                        this.state.errorType === 'subject'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }}>
                            <FaEnvelopeOpenText />
                            <input className="register__text-area" type="text" placeholder="Subject" value={this.state.subject} onChange={this.onSubjectChange} />
                        </div>
                        :
                        <div className="input-box">
                            <FaEnvelopeOpenText />
                            <input className="register__text-area" type="text" placeholder="Subject" value={this.state.subject} onChange={this.onSubjectChange} />
                        </div>
                    }
                    {
                        this.state.errorType === 'message'
                        ?
                        <div style={{  display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <textarea id="message" name="message" placeholder="Write your message here..." rows="15" cols="50" style={{ borderColor: 'red' }} onChange={this.onMessageChange}>
                            </textarea>
                        </div>
                        :
                        <div style={{  display: 'flex', justifyContent: 'center', width: '100%', borderColor: 'red' }}>
                            <textarea id="message" name="message" placeholder="Write your message here..." rows="15" cols="50" onChange={this.onMessageChange}>
                            </textarea>
                        </div>
                    }
                    <div className="register__error-warning">
                        {this.state.errors.length > 0 ? this.state.errors : ''}
                    </div>
                    <div>
                        <div className="register__box__register-button">
                            <button className="register__box__register-button__style" onClick={this.onSubmit}>Send Message</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default ContactUsNoLogin;
