import React from 'react';
import InitialHeader from '../../InitialHeader';
import FiltersPageItem from './FiltersPageItem';

import { wallet } from '../../../services/api';

export class FiltersPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            walletListFilters: [],
            cryptoFiltersLoaded: false
        };
    };

    componentDidMount() {
        this.getWalletFilters();
    }

    getWalletFilters = async () => {
        try {
            const res = await wallet.get('wallet/get-wallet-filters');
            
            await res.data.result.forEach((crypto) => {
                this.setState((previousState) => ({ walletListFilters:  previousState.walletListFilters.concat({ name: crypto.name, symbol: crypto.symbol, selected: !!crypto.selected, quantity: crypto.quantity })}));
                // cryptosFilteredList.push({name: crypto.name, checked: crypto.selected})
            });

            this.setState({ cryptoFiltersLoaded: true });
        } catch (err) {
            console.log(err);
        }
    }

    changeFilterState = (crypto, selected) => {
        const cryptoFiltersAux = this.state.walletListFilters;

        const index = this.state.walletListFilters.findIndex(element => element.name === crypto);

        cryptoFiltersAux[index].selected = +selected;

        this.setState({ walletListFilters: cryptoFiltersAux });
    }

    handleOnClick = () => {
        this.saveCryptoListFilters();
    }
    
    handleOnClickSelectAll = () => {
        this.updateSelectAllAvailable();
    }

    saveCryptoListFilters = async () => {
        try {
            await wallet.post('wallet/save-wallet-filters', {
                cryptosFilteredList: this.state.walletListFilters
            });

            this.props.history.push('/dashboard');
        } catch (err) {
            console.log(err);
        }
    }

    updateSelectAllAvailable = () => {

        const filtersAux = this.state.walletListFilters;
        this.setState({ walletListFilters: [] });
        this.setState({ cryptoFiltersLoaded: false });
        
        const createFilters = async () => {
            return Promise.all(filtersAux.map((crypto) => {
                if (parseFloat(crypto.quantity) === 0) {
                    this.setState((previousState) => ({ walletListFilters:  previousState.walletListFilters.concat({ name: crypto.name, symbol: crypto.symbol, selected: false, quantity: crypto.quantity })}));
                } else {
                    this.setState((previousState) => ({ walletListFilters:  previousState.walletListFilters.concat({ name: crypto.name, symbol: crypto.symbol, selected: true, quantity: crypto.quantity })}));
                }
            // cryptosFilteredList.push({name: crypto.name, checked: crypto.selected})
            }));
        }

        createFilters().then(() => { this.setState({ cryptoFiltersLoaded: true }) });
    };

    render() {
        return (
            <div>
                <InitialHeader title="Filters" history={this.props.history} />
                <div className="content-container" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', marginTop: '60px', height: '80vh', overflowY: 'scroll' }}>
                    {
                        this.state.cryptoFiltersLoaded && <div style = {{ alignItems: 'center', display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <button className="filters-modal__button-select" onClick={this.handleOnClickSelectAll}>Select all available in wallet</button>
                            <div className="list-body" style={{ borderTop: '1px solid #e5e5e5', width: '100%' }}>
                            {
                                this.state.walletListFilters.length === 0 ? (
                                <div className="list-item list-item--message">
                                    <span>There are no available cryptocurrencies in the system.</span>
                                </div>
                                ) : (
                                    this.state.walletListFilters.map((filter) => {
                                        return <FiltersPageItem key={filter.name} {...filter} changeFilterState={this.changeFilterState} />;
                                    })
                                )
                            }
                            </div>
                            <button className="filters-modal__button" onClick={this.handleOnClick}>Save</button>
                        </div>
                    }
                </div>
            </div>
        );
    }
};

export default FiltersPage;
