import React from 'react';
import Modal from 'react-modal';
import { FaCoins, FaEuroSign } from "react-icons/fa";
import Calendar from 'react-calendar';
import validator from 'validator';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        width: '95%',
        height: '40%',
        padding: '0px'
    }
};

export class OrderBuyPageForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            errorType: '',
            priceToBuy: '',
            amount: '',
            selected: '',
            last_price: '',
            modalIsOpen: false,
            startDate: new Date(),
            dateSelected: false,
            limitSelected: false
        };
    };

    openModal = () => {
        this.setState(() => ({ modalIsOpen: true }));
    };

    closeModal = () => {
        this.setState(() => ({ modalIsOpen: false, dateSelected: true }));
    }

    validateFields = () => {
        this.setState(() => ({errors: [], errorType: ''}));

        if (validator.isEmpty(this.state.selected)) {
            this.setState(() => ({errors: ['You must choose one cryptocurrency'], errorType: 'selected'}));
            return false;
        }

        if (this.state.limitSelected && !validator.isDecimal(this.state.priceToBuy, { decimal_digits: '0,2' })) {
            this.setState(() => ({errors: ['You must enter a valid price'], errorType: 'priceToBuy'}));
            return false;
        }

        if (!validator.isDecimal(this.state.amount, { decimal_digits: '0,2' })) {
            this.setState(() => ({errors: ['You must enter a valid amount'], errorType: 'amount'}));
            return false;
        }

        if (Number(this.state.amount) === 0) {
            this.setState(() => ({errors: ['You must enter a valid amount'], errorType: 'amount'}));
            return false;
        }

        if (Number.parseFloat((this.state.amount * this.state.priceToBuy)) > Number.parseFloat(this.props.userBalance)) {
            this.setState(() => ({errors: ['You have insufficient balance'], errorType: 'balance'}));
            return false;
        }

        return true;
    }

    onSubmit = (e) => {
        e.preventDefault();

        if (this.validateFields()) {
            let side = this.state.limitSelected ? 'limit' : 'market';

            if (this.state.dateSelected) {
                this.props.onSubmit(this.state.selected, this.state.priceToBuy, this.state.amount, side,  this.state.startDate);
            } else {
                this.props.onSubmit(this.state.selected, this.state.priceToBuy, this.state.amount, side);
            }
        }
    };

    onPriceToBuyChange = (e) => {
        const priceToBuy = e.target.value;

        if (!priceToBuy || priceToBuy.match(/^\d{1,}(\.\d{0,2})?$/)) {
            this.setState(() => ({ priceToBuy }));
        }
    }

    onAmountChange = (e) => {
        const amount = e.target.value;

        if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
            this.setState(() => ({ amount }));
        }
    };

    onSelectChange = (e) => {
        const { value } = e.target;

        if (value === '') {
            this.setState({ last_price: '', selected: '' });
            this.props.changePrice('', '', '');
        } else {
            if (value.split(':')[3] === 'null'){
                this.setState({ last_price: 'N/A' });
            } else {
                this.setState({ last_price: value.split(':')[3] });
            }
            this.setState({ selected: value.split(':')[0] });
            this.props.changePrice(value.split(':')[1], value.split(':')[2], value.split(':')[4]);
        }
    };

    onDateChange = (e) => {
        this.setState({ startDate: e });
        this.closeModal();
    }

    render() {
        return (
            <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', marginTop: '90px', width: '100%' }}>
                <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontWeight: 'bold' }}>Last Transaction Price:</span>
                    <span>{this.state.last_price} €</span>
                </div>
                {this.state.limitSelected ?
                    <div style={{ paddingBottom: '5px' }}>
                        <button className="trade-order__market-limit" type="button" onClick={() => this.setState({ limitSelected: false })}>Market</button>
                        <button className="trade-order__market-limit trade-order__market-limit--active" type="button" onClick={() => this.setState({ limitSelected: true })}>Limit</button>
                    </div>
                    :
                    <div style={{ paddingBottom: '5px' }}>
                        <button className="trade-order__market-limit trade-order__market-limit--active" type="button" onClick={() => this.setState({ limitSelected: false })}>Market</button>
                        <button id="limit_button" className="trade-order__market-limit" type="button" onClick={() => this.setState({ limitSelected: true })}>Limit</button>
                    </div>
                }
                <form id="buy_form" className="trade-order__form" onSubmit={this.onSubmit}>
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        style={customStyles}
                        contentLabel="Pick date"
                    >
                        <div className="pay-page-modal">
                            <div style={{ display: 'flex', height: '100%', width: '100%'}}>
                                <Calendar
                                    onChange={this.onDateChange}
                                    value={this.state.startDate}
                                />
                            </div>
                        </div>
                    </Modal>
                    <select className="send-page__select" name="cryptos" id="cryptos" onChange={this.onSelectChange} >
                        <option key={''} value={''}>{}</option>
                        {
                            this.props.cryptos.map((crypto) => {
                                return <option key={crypto.name} value={`${crypto.name}:${crypto.ask_price}:${crypto.bid_price}:${crypto.last_eq_price}:${crypto.crypto_id}`}>{crypto.symbol + ' ' + crypto.name}</option>
                            })
                        }
                    </select>
                    {this.state.limitSelected && <div className="input-box">
                        <FaEuroSign />
                        <input
                            name="price"
                            style={{border: "none", backgroundColor: '#e3e3e3'}}
                            type="text"
                            placeholder="Price you wish to buy at"
                            value={this.state.priceToBuy}
                            onChange={this.onPriceToBuyChange}
                        />
                    </div>}
                    <div className="input-box">
                        <FaCoins />
                        <input
                            name="amount"
                            style={{border: "none", backgroundColor: '#e3e3e3'}}
                            type="text"
                            placeholder="Amount"
                            value={this.state.amount}
                            onChange={this.onAmountChange}
                        />
                    </div>
                    <div className="trade-order__good-until">
                        <div  style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
                            <div className="trade-order__good-until__text">Good Until</div>
                            <div style={{ display: 'flex' }}>
                                {this.state.dateSelected ?
                                <div>
                                    <button className="trade-order__good-until__cancelled" type="button" onClick={() => this.setState({ dateSelected: false })}>Cancelled</button>
                                    <button className="trade-order__good-until__time trade-order__good-until__time--active" type="button" onClick={() => this.openModal()}>Time</button>
                                </div>
                                :
                                <div>
                                    <button className="trade-order__good-until__cancelled trade-order__good-until__cancelled--active" type="button" onClick={() => this.setState({ dateSelected: false })}>Cancelled</button>
                                    <button className="trade-order__good-until__time" type="button" onClick={() => this.openModal()}>Time</button>
                                </div>
                                }
                            </div>
                        </div>
                        {
                            this.state.dateSelected &&
                            <div style={{ display: 'flex', fontWeight: 'bold', fontSize: '12px', justifyContent: 'flex-end' }}>
                                {this.state.startDate.toLocaleDateString("pt-PT")}
                            </div>
                        }
                    </div>
                    <div className="trade-order__info-container">
                        <div className="trade-order__info-container__row">
                            <div style={{ fontWeight: 'bold', fontSize: '12px' }}>Available Balance </div>
                            <div style={{ fontWeight: 'bold', fontSize: '12px' }}>{this.props.userBalance} €</div>
                        </div>
                        {this.state.limitSelected && <div className="trade-order__info-container__row">
                            <div style={{ fontWeight: 'bold', fontSize: '12px' }}>Order Total </div>
                            <div style={{ fontWeight: 'bold', fontSize: '12px' }}>{(this.state.amount * this.state.priceToBuy).toFixed(2)} €</div>
                        </div>}
                    </div>
                    <div id="div_error" className="register__error-warning">
                            {this.state.errors.length > 0 ? this.state.errors : ''}
                    </div>
                    <div>
                        <button className="trade-order__button-buy">Place BUY Order</button>
                    </div>
                </form>
            </div>
        );
    }
};

export class OrderSellPageForm extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            errorType: '',
            priceToSell: '',
            amount: '',
            selected: '',
            last_price: '',
            availableBalance: '0',
            availableBalanceCurrency: '',
            startDate: new Date(),
            dateSelected: false,
            limitSelected: false
        };
    };

    openModal = () => {
        this.setState(() => ({ modalIsOpen: true }));
    };

    closeModal = () => {
        this.setState(() => ({ modalIsOpen: false, dateSelected: true }));
    }

    validateFields = () => {
        this.setState(() => ({errors: [], errorType: ''}));

        if (validator.isEmpty(this.state.selected)) {
            this.setState(() => ({errors: ['You must choose one cryptocurrency'], errorType: 'selected'}));
            return false;
        }

        if (this.state.limitSelected && !validator.isDecimal(this.state.priceToSell, { decimal_digits: '0,2' })) {
            this.setState(() => ({errors: ['You must enter a valid price'], errorType: 'priceToSell'}));
            return false;
        }

        if (!validator.isDecimal(this.state.amount, { decimal_digits: '0,2' })) {
            this.setState(() => ({errors: ['You must enter a valid amount'], errorType: 'amount'}));
            return false;
        }

        if (Number(this.state.amount) === 0) {
            this.setState(() => ({errors: ['You must enter a valid amount'], errorType: 'amount'}));
            return false;
        }

        if (Number.parseFloat(this.state.amount) > Number.parseFloat(this.state.availableBalance)) {
            this.setState(() => ({errors: ['You have insufficient balance'], errorType: 'balance'}));
            return false;
        }

        return true;
    }

    onSubmit = (e) => {
        e.preventDefault();

        if (this.validateFields()) {
            let side = this.state.limitSelected ? 'limit' : 'market';

            if (this.state.dateSelected) {
                this.props.onSubmit(this.state.selected, this.state.priceToSell, this.state.amount, side,  this.state.startDate);
            } else {
                this.props.onSubmit(this.state.selected, this.state.priceToSell, this.state.amount, side);
            }
        }
        //this.props.onSubmit();
    };

    onPriceToSellChange = (e) => {
        const priceToSell = e.target.value;

        if (!priceToSell || priceToSell.match(/^\d{1,}(\.\d{0,2})?$/)) {
            this.setState(() => ({ priceToSell }));
        }
    }

    onAmountChange = (e) => {
        const amount = e.target.value;

        if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
            this.setState(() => ({ amount }));
        }
    };

    onSelectChange = (e) => {
        const { value } = e.target;

        if (value === '') {
            this.setState({ selected: '', last_price: '', availableBalanceCurrency: '', availableBalance: '0' });
            this.props.changePrice('', '', '');
        } else {
            if (value.split(':')[3] === 'null'){
                this.setState({ last_price: 'N/A' });
            } else {
                this.setState({ last_price: value.split(':')[3] });
            }
            this.setState({ selected: value.split(':')[0],availableBalanceCurrency: value.split(':')[1], availableBalance: value.split(':')[2] });
            this.props.changePrice(value.split(':')[3], value.split(':')[4], value.split(':')[6]);
        }
    }

    onDateChange = (e) => {
        this.setState({ startDate: e });
        this.closeModal();
    }

    render() {
        return (
            <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', marginTop: '90px', width: '100%' }}>
                <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontWeight: 'bold' }}>Last Transaction Price:</span>
                    <span>{this.state.last_price} €</span>
                </div>
                {this.state.limitSelected ?
                    <div style={{ paddingBottom: '5px' }}>
                        <button className="trade-order__market-limit" type="button" onClick={() => this.setState({ limitSelected: false })}>Market</button>
                        <button className="trade-order__market-limit trade-order__market-limit--active" type="button" onClick={() => this.setState({ limitSelected: true })}>Limit</button>
                    </div>
                    :
                    <div style={{ paddingBottom: '5px' }}>
                        <button className="trade-order__market-limit trade-order__market-limit--active" type="button" onClick={() => this.setState({ limitSelected: false })}>Market</button>
                        <button id="limit_button" className="trade-order__market-limit" type="button" onClick={() => this.setState({ limitSelected: true })}>Limit</button>
                    </div>
                }
                <form id="sell_form" className="trade-order__form" onSubmit={this.onSubmit}>
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        style={customStyles}
                        contentLabel="Pick date"
                    >
                        <div className="pay-page-modal">
                            <div style={{ display: 'flex', height: '100%', width: '100%'}}>
                                <Calendar
                                    onChange={this.onDateChange}
                                    value={this.state.startDate}
                                />
                            </div>
                        </div>
                    </Modal>
                    <select className="send-page__select" name="cryptos" id="cryptos" onChange={this.onSelectChange} >
                        <option key={''} value={''}>{}</option>
                        {
                            this.props.cryptos.map((crypto) => {
                                return <option key={crypto.name} value={`${crypto.name}:${crypto.symbol}:${crypto.quantity}:${crypto.ask_price}:${crypto.bid_price}:${crypto.last_eq_price}:${crypto.crypto_id}`}>{crypto.symbol + ' ' + crypto.name}</option>
                            })
                        }
                    </select>
                    {this.state.limitSelected && <div className="input-box">
                        <FaEuroSign />
                        <input
                            name = "price"
                            style={{border: "none", backgroundColor: '#e3e3e3'}}
                            type="text"
                            placeholder="Price you wish to sell at"
                            value={this.state.priceToSell}
                            onChange={this.onPriceToSellChange}
                        />
                    </div>}
                    <div className="input-box">
                        <FaCoins />
                        <input
                            name="amount"
                            style={{border: "none", backgroundColor: '#e3e3e3'}}
                            type="text"
                            placeholder="Amount"
                            value={this.state.amount}
                            onChange={this.onAmountChange}
                        />
                    </div>
                    <div className="trade-order__good-until">
                        <div  style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
                            <div className="trade-order__good-until__text">Good Until</div>
                            <div style={{ display: 'flex' }}>
                                {this.state.dateSelected ?
                                <div>
                                    <button className="trade-order__good-until__cancelled" type="button" onClick={() => this.setState({ dateSelected: false })}>Cancelled</button>
                                    <button className="trade-order__good-until__time trade-order__good-until__time--active" type="button" onClick={() => this.openModal()}>Time</button>
                                </div>
                                :
                                <div>
                                    <button className="trade-order__good-until__cancelled trade-order__good-until__cancelled--active" type="button" onClick={() => this.setState({ dateSelected: false })}>Cancelled</button>
                                    <button className="trade-order__good-until__time" type="button" onClick={() => this.openModal()}>Time</button>
                                </div>
                                }
                            </div>
                        </div>
                        {
                            this.state.dateSelected &&
                            <div style={{ display: 'flex', fontWeight: 'bold', fontSize: '12px', justifyContent: 'flex-end' }}>
                                {this.state.startDate.toLocaleDateString("pt-PT")}
                            </div>
                        }
                    </div>
                    <div className="trade-order__info-container">
                        <div className="trade-order__info-container__row">
                            <div style={{ fontWeight: 'bold', fontSize: '12px' }}>Available Balance </div>
                            <div style={{ fontWeight: 'bold', fontSize: '12px' }}>{this.state.availableBalance} {this.state.availableBalanceCurrency.split(' ')[0]}</div>
                        </div>
                        <div className="trade-order__info-container__row">
                            <div style={{ fontWeight: 'bold', fontSize: '12px' }}>Order Total </div>
                            <div style={{ fontWeight: 'bold', fontSize: '12px' }}>{(this.state.amount * this.state.priceToSell).toFixed(2)} €</div>
                        </div>
                    </div>
                    <div id="div_error" className="register__error-warning">
                            {this.state.errors.length > 0 ? this.state.errors : ''}
                    </div>
                    <div>
                        <button className="trade-order__button">Place SELL Order</button>
                    </div>
                </form>
            </div>
        );
    }
};
