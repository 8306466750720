import React from 'react';
import { FaLock, FaKey } from "react-icons/fa";
import validator from 'validator';

import { auth } from '../../services/api';

class ResetPasswordForm extends React.Component {

    constructor(props) {
        super(props);

        console.log('debug ', this.props);
        this.state = {
            code: '',
            newPassword: '',
            confirmNewPassword: '',
            errors: [],
            errorType: ''
        };
    };

    onCodeChange = (e) => {
        const code = e.target.value;

        this.setState(() => ({ code }));
    }

    onNewPasswordChange = (e) => {
        const newPassword = e.target.value;

        this.setState(() => ({ newPassword }));
    }

    onConfirmNewPasswordChange = (e) => {
        const confirmNewPassword = e.target.value;

        this.setState(() => ({ confirmNewPassword }));
    }

    validateFields = () => {
        this.setState(() => ({errors: [], errorType: ''}));

        if (!this.state.code.match(/^[0-9]*$/) || !validator.isLength(this.state.code, { min: 4, max: 4 })) {
            this.setState(() => ({errors: ['You must enter a valid 4 digit code'], errorType: 'code'}));
            return false;
        }
        if (this.state.newPassword !== this.state.confirmNewPassword) {
            this.setState(() => ({errors: ['Both password must match'], errorType: 'password'}));
            return false;
        }
        if (!validator.isStrongPassword(this.state.newPassword) || !validator.isLength(this.state.newPassword, { max: 20 })) {
            this.setState(() => ({errors: ['You must enter a valid password (length between 8 and 20, at least 1 lowercase and 1 uppercase letter, 1 number and 1 symbol'], errorType: 'password'}));
            return false;
        }

        return true;
    }

    onSubmit = async (e) => {
        e.preventDefault();

        if (this.validateFields()) {
            try {
                const res = await auth.post('auth/reset-password', {
                    id_user: this.props.userId,
                    reset_code: this.state.code,
                    password: this.state.newPassword,
                    new_password: this.state.confirmNewPassword
                });
    
                this.props.onSubmit();
            } catch (err) {
                console.log(err)
                this.setState(() => ({errors: [err.response.data.errors[0].message], errorType: ''}));
            }
        }
    };

    render () {
        return (
            <form className="password-recovery__form" onSubmit={this.onSubmit}>
                <div className="password-recovery__inputs-container">
                    <div className="input-box">
                        <FaKey />
                        <input style={{border: "none"}} type="number" placeholder="Four Digit Code" value={this.state.code} onChange={this.onCodeChange} />
                    </div>
                    <div className="input-box">
                        <FaLock />
                        <input style={{border: "none"}} type="password" placeholder="Password" value={this.state.newPassword} onChange={this.onNewPasswordChange} />
                    </div>
                    <div className="input-box">
                        <FaLock />
                        <input style={{border: "none"}} type="password" placeholder="Confirm Password" value={this.state.confirmNewPassword} onChange={this.onConfirmNewPasswordChange} />
                    </div>
                </div>
                <div className="register__error-warning">
                        {this.state.errors.length > 0 ? this.state.errors : ''}
                </div>
                <div>
                    <button className="password-recovery__button">Reset Password</button>
                </div>
            </form>
        );
    };
};

export default ResetPasswordForm;