import React from 'react';
import { Link } from 'react-router-dom';
import { FaWallet, FaMoneyBill, FaHandHoldingUsd, FaExchangeAlt, FaBriefcase } from 'react-icons/fa';

const Footer = (props) => (
    <header className="footer">
        <div className="footer__content">
            {
                props.location.pathname === '/dashboard' ? 
                (
                    <Link className="footer__button" style={{ backgroundColor: '#166c98' }} to="/dashboard">
                        <button className="button button--link" style={{ padding: '0px' }}><FaWallet /></button>
                        <span style={{ color: 'white', fontWeight: 'bold', fontSize: 'smaller' }}>Wallet</span>
                    </Link>
                ) : 
                (
                    <Link className="footer__button" to="/dashboard">
                        <button className="button button--link" style={{ padding: '0px' }}><FaWallet /></button>
                        <span style={{ color: 'white', fontWeight: 'bold', fontSize: 'smaller' }}>Wallet</span>
                    </Link>
                )
            }
            {
                (props.location.pathname === '/pay' || props.location.pathname === '/send') ? 
                (
                    <Link className="footer__button" style={{ backgroundColor: '#166c98' }} to="/pay">
                        <button className="button button--link" style={{ padding: '0px' }}><FaMoneyBill /></button>
                        <span style={{ color: 'white', fontWeight: 'bold', fontSize: 'smaller' }}>Pay/Send</span>
                    </Link>
                ) : 
                (
                    <Link className="footer__button" to="/pay">
                        <button className="button button--link" style={{ padding: '0px' }}><FaMoneyBill /></button>
                        <span style={{ color: 'white', fontWeight: 'bold', fontSize: 'smaller' }}>Pay/Send</span>
                    </Link>
                )
            }
            {
                props.location.pathname === '/request' ? 
                (
                    <Link className="footer__button" style={{ backgroundColor: '#166c98' }} to="/request">
                        <button className="button button--link" style={{ padding: '0px' }}><FaHandHoldingUsd /></button>
                        <span style={{ color: 'white', fontWeight: 'bold', fontSize: 'smaller' }}>Request</span>
                    </Link>
                ) : 
                (
                    <Link className="footer__button" to="/request">
                        <button className="button button--link" style={{ padding: '0px' }}><FaHandHoldingUsd /></button>
                        <span style={{ color: 'white', fontWeight: 'bold', fontSize: 'smaller' }}>Request</span>
                    </Link>
                )
            }
            {
                (/*props.location.pathname === '/trade-sell' || props.location.pathname === '/trade-buy' ||*/ props.location.pathname === '/order-sell' || props.location.pathname === '/order-buy') ? 
                (
                    <Link className="footer__button" style={{ backgroundColor: '#166c98' }} to="/order-sell">
                        <button className="button button--link" style={{ padding: '0px' }}><FaExchangeAlt /></button>
                        <span style={{ color: 'white', fontWeight: 'bold', fontSize: 'smaller' }}>Trade</span>
                    </Link>
                ) : 
                (
                    <Link className="footer__button" to="/order-sell">
                        <button className="button button--link" style={{ padding: '0px' }}><FaExchangeAlt /></button>
                        <span style={{ color: 'white', fontWeight: 'bold', fontSize: 'smaller' }}>Trade</span>
                    </Link>
                )
            }
            {
                (/*props.location.pathname === '/portfolio-positions-open' || props.location.pathname === '/portfolio-positions-closed' ||*/ props.location.pathname === '/my-orders-open' || props.location.pathname === '/my-orders-closed')  ? 
                (
                    <Link className="footer__button" style={{ backgroundColor: '#166c98' }} to="/my-orders-open">
                        <button className="button button--link" style={{ padding: '0px' }}><FaBriefcase /></button>
                        <span style={{ color: 'white', fontWeight: 'bold', fontSize: 'smaller' }}>My Orders</span>
                    </Link>
                ) : 
                (
                    <Link className="footer__button" to="/my-orders-open">
                        <button className="button button--link" style={{ padding: '0px' }}><FaBriefcase /></button>
                        <span style={{ color: 'white', fontWeight: 'bold', fontSize: 'smaller' }}>My Orders</span>
                    </Link>
                )
            }
        </div>
    </header>
);

export default Footer;
