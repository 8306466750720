import React from 'react';
import GifPlayer from 'react-gif-player';
import InitialHeader from '../InitialHeader';

import loginGif from '../../assets/gifs_help/login.gif';
import loginImg from '../../assets/gifs_help/login_img.jpg';

import logoutGif from '../../assets/gifs_help/logout.gif';
import logoutImg from '../../assets/gifs_help/logout_img.jpg';

import changeInfoGif from '../../assets/gifs_help/change_info.gif';
import changeInfoImg from '../../assets/gifs_help/change_info_img.jpg';

import resetPasswordGif from '../../assets/gifs_help/reset_password.gif';
import resetPasswordImg from '../../assets/gifs_help/reset_password_img.jpg';

import changePINGif from '../../assets/gifs_help/changePIN.gif';
import changePINImg from '../../assets/gifs_help/changePINImg.jpg';

import contactLogoutGif from '../../assets/gifs_help/contact_logout.gif';
import contactLogoutImg from '../../assets/gifs_help/contact_logout_img.jpg';

import changeWalletFiltersGif from '../../assets/gifs_help/change_wallet_filters.gif';
import changeWalletFiltersImg from '../../assets/gifs_help/change_wallet_filters_img.jpg';

import checkWalletActivityGif from '../../assets/gifs_help/check_wallet_activity.gif';
import checkWalletActivityImg from '../../assets/gifs_help/check_wallet_activity_img.jpg';

import checkCryptoInfoGif from '../../assets/gifs_help/check_crypto_info.gif';
import checkCryptoInfoImg from '../../assets/gifs_help/check_crypto_info_img.jpg';

import makePaymentGif from '../../assets/gifs_help/make_payment.gif';
import makePaymentImg from '../../assets/gifs_help/make_payment_img.jpg';

import sendCryptoGif from '../../assets/gifs_help/send_crypto.gif';
import sendCryptoImg from '../../assets/gifs_help/send_crypto_img.jpg';

import requestCryptoGif from '../../assets/gifs_help/request_crypto.gif';
import requestCryptoImg from '../../assets/gifs_help/request_crypto_img.jpg';

import makeOrderGif from '../../assets/gifs_help/make_order.gif';
import makeOrderImg from '../../assets/gifs_help/make_order_img.jpg';

import checkPortfolioGif from '../../assets/gifs_help/check_portfolio.gif';
import checkPortfolioImg from '../../assets/gifs_help/check_portfolio_img.jpg';

import './gifplayer.scss';

export class LoginLogout extends React.Component {

    render() {
        return (
            <div>
                <InitialHeader title="Log in and out of Crypta Project" history={this.props.history} />
                <div className="content-container" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '80vh', justifyContent: 'space-between', marginBottom: '70px', marginTop: '70px', overflowY: 'scroll', width: '100vw' }}>
                    <div style={{ marginBottom: '15px' }}>
                        <GifPlayer gif={loginGif} still={loginImg} style={{ height: '250px', width: '150px' }} />
                    </div>
                    <div className="personal-info-page" style={{ alignItems: 'flex-start', flexDirection: 'column', marginTop: 0, padding: '5px' }}>
                        <div style={{ alignSelf: 'center', fontFamily: 'courier, courier new, serif', fontWeight: 'bold' }}>Login steps:</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>1.</span> Access the website URl (crypta.dei.uc.pt);</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>2.</span> Type your credentials (phone number and password);</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>3.</span> Press the Login button.</div>
                    </div>
                    <div style={{ marginBottom: '15px', marginTop: '15px' }}>
                        <GifPlayer gif={logoutGif} still={logoutImg} style={{ height: '250px', width: '150px' }} />
                    </div>
                    <div className="personal-info-page" style={{ alignItems: 'flex-start', flexDirection: 'column', marginTop: 0, padding: '5px' }}>
                        <div style={{ alignSelf: 'center', fontFamily: 'courier, courier new, serif', fontWeight: 'bold' }}>Logout steps:</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}>1. Press the cogwheel button at the middle of the top bar;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}>2. Press the Logout button.</div>
                    </div>
                </div>
            </div>
        );
    }
};

export class ChangePersonalInfo extends React.Component {

    render() {
        return (
            <div>
                <InitialHeader title="Change your personal information or password" history={this.props.history} />
                <div className="content-container" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '80vh', justifyContent: 'space-between', marginBottom: '70px', marginTop: '70px', overflowY: 'scroll', width: '100vw' }}>
                    <div>
                        <GifPlayer gif={changeInfoGif} still={changeInfoImg} style={{ height: '250px', width: '150px' }} />
                    </div>
                    <div className="personal-info-page" style={{ alignItems: 'flex-start', flexDirection: 'column', marginTop: 0, overflowY: 'auto', padding: '5px', wordBreak: 'break-word' }}>
                        <div style={{ alignSelf: 'center', fontFamily: 'courier, courier new, serif', fontWeight: 'bold' }}>Change personal info (full name or password) steps:</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>1.</span> Press the cogwheel button at the middle of the top bar;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>2.</span> Press the personal info button;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>3.</span> Change the full name field or type a new passwrd and confirm it.</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>4.</span> Press the edit personal info button.</div>
                    </div>
                </div>
            </div>
        );
    }
};

export class ResetPassword extends React.Component {

    render() {
        return (
            <div>
                <InitialHeader title="Reset your password" history={this.props.history} />
                <div className="content-container" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '80vh', justifyContent: 'space-between', marginBottom: '70px', marginTop: '70px', overflowY: 'scroll', width: '100vw' }}>
                    <div>
                        <GifPlayer gif={resetPasswordGif} still={resetPasswordImg} style={{ height: '250px', width: '150px' }} />
                    </div>
                    <div className="personal-info-page" style={{ alignItems: 'flex-start', flexDirection: 'column', marginTop: 0, overflowY: 'auto', padding: '5px', wordBreak: 'break-word' }}>
                        <div style={{ alignSelf: 'center', fontFamily: 'courier, courier new, serif', fontWeight: 'bold' }}>Reset password steps:</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>1.</span> Access the website URl (crypta.dei.uc.pt);</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>2.</span> Press the "forgot password?" button;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>3.</span> Type your phone number or email;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>4.</span> Press the confirm button;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>5.</span> Then enter the four digit code your received in the email and the new password;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>6.</span> Finally press the reset password button.</div>
                    </div>
                </div>
            </div>
        );
    }
};

export class ChangePIN extends React.Component {

    render() {
        return (
            <div>
                <InitialHeader title="Change your PIN code" history={this.props.history} />
                <div className="content-container" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '80vh', justifyContent: 'space-between', marginBottom: '70px', marginTop: '70px', overflowY: 'scroll', width: '100vw' }}>
                    <div>
                        <GifPlayer gif={changePINGif} still={changePINImg} style={{ height: '250px', width: '150px' }} />
                    </div>
                    <div className="personal-info-page" style={{ alignItems: 'flex-start', flexDirection: 'column', marginTop: 0, overflowY: 'auto', padding: '5px', wordBreak: 'break-word' }}>
                        <div style={{ alignSelf: 'center', fontFamily: 'courier, courier new, serif', fontWeight: 'bold' }}>Change PIN code steps:</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>1.</span> Press the cogwheel button at the middle of the top bar;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>2.</span> Press the security button;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>3.</span> Type the new PIN code and confirm it.</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>4.</span> Press the change PIN code button.</div>
                    </div>
                </div>
            </div>
        );
    }
};

export class ContactLogout extends React.Component {

    render() {
        return (
            <div>
                <InitialHeader title="Contact support while logged out" history={this.props.history} />
                <div className="content-container" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '80vh', justifyContent: 'space-between', marginBottom: '70px', marginTop: '70px', overflowY: 'scroll', width: '100vw' }}>
                    <div>
                        <GifPlayer gif={contactLogoutGif} still={contactLogoutImg} style={{ height: '250px', width: '150px' }} />
                    </div>
                    <div className="personal-info-page" style={{ alignItems: 'flex-start', flexDirection: 'column', marginTop: 0, overflowY: 'auto', padding: '5px', wordBreak: 'break-word' }}>
                        <div style={{ alignSelf: 'center', fontFamily: 'courier, courier new, serif', fontWeight: 'bold' }}>Contact while logged out steps:</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>1.</span> Access the website URl (crypta.dei.uc.pt);</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>2.</span> Press the "Need help?" button;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>3.</span> Type your email, name, subject of the contact and the message;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>4.</span> Press the confirm button;</div>
                    </div>
                </div>
            </div>
        );
    }
};

export class ChangeWalletFilters extends React.Component {

    render() {
        return (
            <div>
                <InitialHeader title="Change your wallet filters" history={this.props.history} />
                <div className="content-container" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '80vh', justifyContent: 'space-between', marginBottom: '70px', marginTop: '70px', overflowY: 'scroll', width: '100vw' }}>
                    <div>
                        <GifPlayer gif={changeWalletFiltersGif} still={changeWalletFiltersImg} style={{ height: '250px', width: '150px' }} />
                    </div>
                    <div className="personal-info-page" style={{ alignItems: 'flex-start', flexDirection: 'column', marginTop: 0, overflowY: 'auto', padding: '5px', wordBreak: 'break-word' }}>
                        <div style={{ alignSelf: 'center', fontFamily: 'courier, courier new, serif', fontWeight: 'bold' }}>Change wallet filters steps:</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>1.</span> Press the filter button in the wallet menu;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>2.</span> Select the desired cryptocurrencies to show in the wallet menu;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>3.</span> Press the save button at the bottom.</div>
                    </div>
                </div>
            </div>
        );
    }
};

export class CheckWalletActivity extends React.Component {

    render() {
        return (
            <div>
                <InitialHeader title="Check your wallet activity" history={this.props.history} />
                <div className="content-container" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '80vh', justifyContent: 'space-between', marginBottom: '70px', marginTop: '70px', overflowY: 'scroll', width: '100vw' }}>
                    <div>
                        <GifPlayer gif={checkWalletActivityGif} still={checkWalletActivityImg} style={{ height: '250px', width: '150px' }} />
                    </div>
                    <div className="personal-info-page" style={{ alignItems: 'flex-start', flexDirection: 'column', marginTop: 0, overflowY: 'auto', padding: '5px', wordBreak: 'break-word' }}>
                        <div style={{ alignSelf: 'center', fontFamily: 'courier, courier new, serif', fontWeight: 'bold' }}>Check wallet activity steps:</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>1.</span> Check the three latest transactions in the wallet menu;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>2.</span> Press the view all activity button to check every transaction made.</div>
                    </div>
                </div>
            </div>
        );
    }
};

export class CheckCryptoInfo extends React.Component {

    render() {
        return (
            <div>
                <InitialHeader title="Check cryptocurrency information" history={this.props.history} />
                <div className="content-container" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '80vh', justifyContent: 'space-between', marginBottom: '70px', marginTop: '70px', overflowY: 'scroll', width: '100vw' }}>
                    <div>
                        <GifPlayer gif={checkCryptoInfoGif} still={checkCryptoInfoImg} style={{ height: '250px', width: '150px' }} />
                    </div>
                    <div className="personal-info-page" style={{ alignItems: 'flex-start', flexDirection: 'column', marginTop: 0, overflowY: 'auto', padding: '5px', wordBreak: 'break-word' }}>
                        <div style={{ alignSelf: 'center', fontFamily: 'courier, courier new, serif', fontWeight: 'bold' }}>Check cryptocurrency information steps:</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>1.</span> Go to the wallet menu;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>2.</span> Press on top of one of the cryptocurrencies.</div>
                    </div>
                </div>
            </div>
        );
    }
};

export class MakePayment extends React.Component {

    render() {
        return (
            <div>
                <InitialHeader title="Make a payment" history={this.props.history} />
                <div className="content-container" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '80vh', justifyContent: 'space-between', marginBottom: '70px', marginTop: '70px', overflowY: 'scroll', width: '100vw' }}>
                    <div>
                        <GifPlayer gif={makePaymentGif} still={makePaymentImg} style={{ height: '250px', width: '150px' }} />
                    </div>
                    <div className="personal-info-page" style={{ alignItems: 'flex-start', flexDirection: 'column', marginTop: 0, overflowY: 'auto', padding: '5px', wordBreak: 'break-word' }}>
                        <div style={{ alignSelf: 'center', fontFamily: 'courier, courier new, serif', fontWeight: 'bold' }}>Make a payment steps:</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>1.</span> Go to the pay/send menu;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>2.</span> Give permissions to access the phone camera (if needed);</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>3.</span> Point your phone camera to the QR code in order to scan it;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>4.</span> Review the transaction details and accept it;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>5.</span> Insert your PIN code.</div>
                    </div>
                </div>
            </div>
        );
    }
};

export class SendCrypto extends React.Component {

    render() {
        return (
            <div>
                <InitialHeader title="Send cryptocurrency to another user" history={this.props.history} />
                <div className="content-container" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '80vh', justifyContent: 'space-between', marginBottom: '70px', marginTop: '70px', overflowY: 'scroll', width: '100vw' }}>
                    <div>
                        <GifPlayer gif={sendCryptoGif} still={sendCryptoImg} style={{ height: '250px', width: '150px' }} />
                    </div>
                    <div className="personal-info-page" style={{ alignItems: 'flex-start', flexDirection: 'column', marginTop: 0, overflowY: 'auto', padding: '5px', wordBreak: 'break-word' }}>
                        <div style={{ alignSelf: 'center', fontFamily: 'courier, courier new, serif', fontWeight: 'bold' }}>Send cryptocurrency steps:</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>1.</span> Go to the pay/send menu;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>2.</span> Change to the "Send" tab in the bottom bar;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>3.</span> Insert the phone number of the user you want to send cryptocurrency to, the amount and the cryptocurrency;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>4.</span> Press the send button;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>5.</span> Review the transaction details and accept it;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>5.</span> Insert your PIN code.</div>
                    </div>
                </div>
            </div>
        );
    }
};

export class RequestCrypto extends React.Component {

    render() {
        return (
            <div>
                <InitialHeader title="Request an amount of cryptocurrency" history={this.props.history} />
                <div className="content-container" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '80vh', justifyContent: 'space-between', marginBottom: '70px', marginTop: '70px', overflowY: 'scroll', width: '100vw' }}>
                    <div>
                        <GifPlayer gif={requestCryptoGif} still={requestCryptoImg} style={{ height: '250px', width: '150px' }} />
                    </div>
                    <div className="personal-info-page" style={{ alignItems: 'flex-start', flexDirection: 'column', marginTop: 0, overflowY: 'auto', padding: '5px', wordBreak: 'break-word' }}>
                        <div style={{ alignSelf: 'center', fontFamily: 'courier, courier new, serif', fontWeight: 'bold' }}>Request cryptocurrency steps:</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>1.</span> Go to the request menu;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>2.</span> Insert the amount and select the cryptocurrency;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>3.</span> Press the generate QR code button;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>4.</span> Copy the generated QR code or show it to the other user.</div>
                    </div>
                </div>
            </div>
        );
    }
};

export class MakeOrder extends React.Component {

    render() {
        return (
            <div>
                <InitialHeader title="Make order (buy/sell and market/limit) in the market" history={this.props.history} />
                <div className="content-container" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '80vh', justifyContent: 'space-between', marginBottom: '70px', marginTop: '70px', overflowY: 'scroll', width: '100vw' }}>
                    <div>
                        <GifPlayer gif={makeOrderGif} still={makeOrderImg} style={{ height: '250px', width: '150px' }} />
                    </div>
                    <div className="personal-info-page" style={{ alignItems: 'flex-start', flexDirection: 'column', marginTop: 0, overflowY: 'auto', padding: '5px', wordBreak: 'break-word' }}>
                        <div style={{ alignSelf: 'center', fontFamily: 'courier, courier new, serif', fontWeight: 'bold' }}>Make order steps:</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>1.</span> Go to the trade menu;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>2.</span> Choose whether you want to sell or buy in the top bar;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>3.</span> Then select market or limit order;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>4.</span> Insert the required information to create an order;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>5.</span> Press the place sell/buy order button;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>6.</span> Finally insert your PIN code.</div>
                    </div>
                </div>
            </div>
        );
    }
};

export class CheckPortfolio extends React.Component {

    render() {
        return (
            <div>
                <InitialHeader title="Check portfolio with open and closed orders" history={this.props.history} />
                <div className="content-container" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '80vh', justifyContent: 'space-between', marginBottom: '70px', marginTop: '70px', overflowY: 'scroll', width: '100vw' }}>
                    <div>
                        <GifPlayer gif={checkPortfolioGif} still={checkPortfolioImg} style={{ height: '250px', width: '150px' }} />
                    </div>
                    <div className="personal-info-page" style={{ alignItems: 'flex-start', flexDirection: 'column', marginTop: 0, overflowY: 'auto', padding: '5px', wordBreak: 'break-word' }}>
                        <div style={{ alignSelf: 'center', fontFamily: 'courier, courier new, serif', fontWeight: 'bold' }}>Check portfolio steps:</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>1.</span> Go to the portfolio menu;</div>
                        <div style={{ fontFamily: 'courier, courier new, serif', fontSize: '14px' }}><span style={{ fontWeight: 'bold' }}>2.</span> Choose whether you want to check open or closed orders at the top bar;</div>
                    </div>
                </div>
            </div>
        );
    }
};