import React from 'react';
import { transactions } from '../../services/api';
import InitialHeader from '../InitialHeader';
import ActivityItem from './ActivityItem';

export class AllActivity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            allActivityList: [],
            allActivityListLoaded: false
        };
    }

    componentDidMount() {
        this.getAllActivity();
    }

    getAllActivity = async () => {
        try {
            const res = await transactions.get('transactions/get-all-activity');
            console.log(res.data);
            this.setState({ allActivityList: res.data.result });
            this.setState({ allActivityListLoaded: true });
        } catch (err) {
            console.log(err);
        }
    }
    
    render() {
        return (
            <div>
                <InitialHeader title="All Activity" history={this.props.history} />
                <div className="content-container" style={{ marginBottom: '70px', marginTop: '60px' }} >
                    {this.state.allActivityListLoaded &&
                        <div className="list-body" style={{ borderTop: '1px solid #e5e5e5' }}>
                        {
                            this.state.allActivityList.length === 0 ? (
                            <div className="list-item list-item--message">
                                <span>You have no activity to show.</span>
                            </div>
                            ) : (
                                this.state.allActivityList.map((activity, index) => {
                                    return <ActivityItem key={index } {...activity} />;
                                })
                            )
                        }
                        </div>
                    }
                </div>
            </div>
        );
    }
};

export default AllActivity;