import React from 'react';
import validator from 'validator';
import { FaRegUserCircle } from 'react-icons/fa';
import InitialHeader from '../InitialHeader';
import { FaLock, FaUser } from "react-icons/fa";

import { auth } from '../../services/api';

export class PersonalInfoPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fullName: '',
            lastFullName: '',
            email: '',
            phone_number: '',
            role: '',
            new_password: '',
            new_password_confirm: '',
            errors: [],
            errorType: '',
            currentProfileLoaded: false
        };
    }

    componentDidMount() {
        this.getCurrentProfile();
    }

    getCurrentProfile = async () => {
        try {
            const res = await auth.get('auth/current-profile');
            
            this.setState({ fullName: res.data.full_name, lastFullName: res.data.full_name, email: res.data.email, phone_number: res.data.phone_number, role: res.data.role, currentProfileLoaded: true });
        } catch (err) {
            console.log(err);
        }
    }

    onFullNameChange = (e) => {
        const fullName = e.target.value;

        this.setState(() => ({ fullName }));
    }

    onNewPasswordChange = (e) => {
        const new_password = e.target.value;

        this.setState(() => ({ new_password }));
    }

    onNewPasswordConfirmChange = (e) => {
        const new_password_confirm = e.target.value;

        this.setState(() => ({ new_password_confirm }));
    }

    validateFields = () => {
        this.setState(() => ({errors: [], errorType: ''}));

        if (this.state.new_password.length !== 0) {
            if (!this.state.fullName.match(/^[a-zA-Z \u00C0-\u00FF]*$/) || !validator.isLength(this.state.fullName, { min: 5 })) {
                this.setState(() => ({errors: ['You must enter a valid full name with only letters and at least 5 characters'], errorType: 'full_name'}));
                return false;
            }
            if (this.state.new_password !== this.state.new_password_confirm) {
                this.setState(() => ({errors: ['Both password must match'], errorType: 'new_password'}));
                return false;
            }
            if (!validator.isStrongPassword(this.state.new_password) || !validator.isLength(this.state.new_password, { max: 20 })) {
                this.setState(() => ({errors: ['You must enter a valid password (length between 8 and 20, at least 1 lowercase and 1 uppercase letter, 1 number and 1 symbol'], errorType: 'new_password'}));
                return false;
            }
        }

        return true;
    }

    onSubmit = async (e) => {
        e.preventDefault();

        if (this.validateFields()) {
            if (this.state.fullName !== this.state.lastFullName && this.state.new_password.length === 0) {
                try {
                    const res = await auth.post('auth/edit-full-name', {
                        full_name: this.state.fullName
                    });
        
                    this.props.history.push('/settings');
                } catch (err) {
                    this.setState(() => ({errors: [err.response.data.errors[0].message], errorType: ''}));
                }
            } else if (this.state.fullName === this.state.lastFullName && this.state.new_password.length !== 0) {
                try {
                    const res = await auth.post('auth/edit-password', {
                        new_password: this.state.new_password
                    });
        
                    this.props.history.push('/settings');
                } catch (err) {
                    this.setState(() => ({errors: [err.response.data.errors[0].message], errorType: ''}));
                }
            } else if (this.state.fullName !== this.state.lastFullName && this.state.new_password.length !== 0) {
                try {
                    const res_fullName = await auth.post('auth/edit-full-name', {
                        full_name: this.state.fullName
                    });
        
                    const res_newPassword = await auth.post('auth/edit-password', {
                        new_password: this.state.new_password
                    });

                    this.props.history.push('/settings');
                } catch (err) {
                    this.setState(() => ({errors: [err.response.data.errors[0].message], errorType: ''}));
                }
            } else {
                this.props.history.push('/settings');
            }
        }
    }

    render() {
        return (
            <div>
                <InitialHeader title="Personal Info" history={this.props.history} />
                <div className="content-container" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '60vh', justifyContent: 'space-between', width: '100vw' }}>
                    <div className="personal-info-page">
                        {
                            this.state.currentProfileLoaded &&
                            <div style={{ alignItems: 'center', display: 'flex', height: '100%', justifyContent: 'space-around', padding: '10px', width: '100%' }}>
                                <FaRegUserCircle size={50} />
                                <div style={{ display: 'flex', flexDirection: 'column', height: '60%', justifyContent: 'space-around', width: '60%' }}>
                                    <div className="personal-info-page__profile-name" style={{ overflowY:'auto', wordBreak: 'break-word' }}>{this.state.lastFullName}</div>
                                    <div className="personal-info-page__profile-email">{this.state.email}</div>
                                    <div className="personal-info-page__profile-email">{this.state.phone_number}</div>
                                </div>
                            </div>
                        }
                    </div>
                    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '25%' }}>
                        {
                            this.state.errorType === 'full_name'
                            ? 
                            <div className="input-box" style={{ borderColor: 'red' }}>
                                <FaUser />
                                <input className="register__text-area" type="text" placeholder="Full Name" value={this.state.fullName} onChange={this.onFullNameChange} />
                            </div>
                            :
                            <div className="input-box">
                                <FaUser />
                                <input className="register__text-area" type="text" placeholder="Full Name" value={this.state.fullName} onChange={this.onFullNameChange} />
                            </div>
                        }
                        {
                            this.state.errorType === 'new_password'
                            ? 
                            <div className="input-box" style={{ borderColor: 'red' }}>
                                <FaLock />
                                <input className="register__text-area" type="password" placeholder="New Password" value={this.state.new_password} onChange={this.onNewPasswordChange} />
                            </div>
                            :
                            <div className="input-box">
                                <FaLock />
                                <input className="register__text-area" type="password" placeholder="New Password" value={this.state.new_password} onChange={this.onNewPasswordChange} />
                            </div>
                        }
                        {
                            this.state.errorType === 'new_password_confirm'
                            ? 
                            <div className="input-box" style={{ borderColor: 'red' }}>
                                <FaLock />
                                <input className="register__text-area" type="password" placeholder="New Password Confirm" value={this.state.new_password_confirm} onChange={this.onNewPasswordConfirmChange} />
                            </div>
                            :
                            <div className="input-box">
                                <FaLock />
                                <input className="register__text-area" type="password" placeholder="New Password Confirm" value={this.state.new_password_confirm} onChange={this.onNewPasswordConfirmChange} />
                            </div>
                        }
                    </div>
                    <div className="register__error-warning">
                        {this.state.errors.length > 0 ? this.state.errors : ''}
                    </div>
                    <div>
                        <div className="register__box__register-button">
                            <button className="register__box__register-button__style" onClick={this.onSubmit}>Edit Personal Info</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default PersonalInfoPage;
