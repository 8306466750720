import React from 'react';
import { FooterSecondaryTradeOrder } from '../FooterSecondary';
import { TradeBuyPageForm, TradeSellPageForm } from './TradePageForm';

export class TradeBuyPage extends React.Component {

    onSubmit = () => {
        this.props.history.push('/pin-code');
    };


    onClickSell = () => {
        this.props.history.push('/trade-sell');
    }

    render () {
        return (
            <div className="trade-order__page">
                <div className="trade-order__header">
                    <button className="trade-order__header__button-inactive" onClick={this.onClickSell}>Sell</button>
                    <button className="trade-order__header__button-buy-active">Buy</button>
                </div>
                <TradeBuyPageForm />
                <FooterSecondaryTradeOrder titleOne="Trade" titleSecond="Order" {...this.props} />
            </div>
        );
    }
};

export class TradeSellPage extends React.Component {

    onSubmit = () => {
        this.props.history.push('/pin-code');
    };

    onClickBuy = () => {
        this.props.history.push('/trade-buy');
    }

    render () {
        return (
            <div className="trade-order__page">
                <div className="trade-order__header">
                    <button className="trade-order__header__button-sell-active">Sell</button>
                    <button className="trade-order__header__button-inactive" onClick={this.onClickBuy}>Buy</button>
                </div>
                <TradeSellPageForm onSubmit={this.onSubmit} />
                <FooterSecondaryTradeOrder titleOne="Trade" titleSecond="Order" {...this.props} />
            </div>
        );
    }
};