import React from 'react';
import { Link } from 'react-router-dom';

export const FooterSecondaryPaySend = (props) => (
    (props.location.pathname === '/pay') ? 
    (
        <header className="footer-secondary">
            <div className="footer-secondary__column">
                <Link to="/pay" className="footer-secondary__link-active">
                    <button className="footer-secondary__link-active">{props.titleOne}</button>
                </Link>
            </div>
            <div className="footer-secondary__column">
                <Link to="/send" className="footer-secondary__link-inactive">
                    <button className="footer-secondary__link-inactive">{props.titleSecond}</button>
                </Link>
            </div>
        </header>
    ) : 
    (
        <header className="footer-secondary">
            <div className="footer-secondary__column">
                <Link to="/pay" className="footer-secondary__link-inactive">
                    <button className="footer-secondary__link-inactive">{props.titleOne}</button>
                </Link>
            </div>
            <div className="footer-secondary__column">
                <Link to="/send" className="footer-secondary__link-active">
                    <button className="footer-secondary__link-active">{props.titleSecond}</button>
                </Link>
            </div>
        </header>
    )
);

export const FooterSecondaryTradeOrder = (props) => (
    (props.location.pathname === '/trade-buy' || props.location.pathname === '/trade-sell') ? 
    (
        <header className="footer-secondary">
            <div className="footer-secondary__column">
                <Link to="/trade-sell" className="footer-secondary__link-active">
                    <button className="footer-secondary__link-active">{props.titleOne}</button>
                </Link>
            </div>
            <div className="footer-secondary__column">
                <Link to="/order-sell" className="footer-secondary__link-inactive">
                    <button className="footer-secondary__link-inactive">{props.titleSecond}</button>
                </Link>
            </div>
        </header>
    ) : 
    (
        <header className="footer-secondary">
            <div className="footer-secondary__column">
                <Link to="/trade-sell" className="footer-secondary__link-inactive">
                    <button className="footer-secondary__link-inactive">{props.titleOne}</button>
                </Link>
            </div>
            <div className="footer-secondary__column">
                <Link to="/order-sell" className="footer-secondary__link-active">
                    <button className="footer-secondary__link-active">{props.titleSecond}</button>
                </Link>
            </div>
        </header>
    )
);

export const FooterSecondaryPortfolio = (props) => (
    (props.location.pathname === '/portfolio-positions-open' || props.location.pathname === '/portfolio-positions-closed') ? 
    (
        <header className="footer-secondary">
            <div className="footer-secondary__column">
                <Link to="/portfolio-positions-open" className="footer-secondary__link-active">
                    <button className="footer-secondary__link-active">{props.titleOne}</button>
                </Link>
            </div>
            <div className="footer-secondary__column">
                <Link to="/portfolio-orders-open" className="footer-secondary__link-inactive">
                    <button className="footer-secondary__link-inactive">{props.titleSecond}</button>
                </Link>
            </div>
        </header>
    ) : 
    (
        <header className="footer-secondary">
            <div className="footer-secondary__column">
                <Link to="/portfolio-positions-open" className="footer-secondary__link-inactive">
                    <button className="footer-secondary__link-inactive">{props.titleOne}</button>
                </Link>
            </div>
            <div className="footer-secondary__column">
                <Link to="/portfolio-orders-open" className="footer-secondary__link-active">
                    <button className="footer-secondary__link-active">{props.titleSecond}</button>
                </Link>
            </div>
        </header>
    )
);