import axios from 'axios';

export const auth = axios.create({
    baseURL: `https://crypta.dei.uc.pt/api/`,
    withCredentials: true
});

export const crypto = axios.create({
    baseURL: `https://crypta.dei.uc.pt/api/`,
    withCredentials: true
});

export const wallet = axios.create({
    baseURL: `https://crypta.dei.uc.pt/api/`,
    withCredentials: true
});

export const transactions = axios.create({
    baseURL: `https://crypta.dei.uc.pt/api/`,
    withCredentials: true
});

export const market = axios.create({
    baseURL: `https://crypta.dei.uc.pt/api/`,
    withCredentials: true
});

export const logs = axios.create({
    baseURL: `https://crypta.dei.uc.pt/api/`,
    withCredentials: true
});
