import React from 'react';
import Switch from 'react-switch';

class FiltersPageItem extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = { checked: !!this.props.selected };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(checked) {
        this.setState({ checked });
        this.props.changeFilterState(this.props.name, checked);
    }

    render () {
        return(
            <div className="list-item">
                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <h3 className="list-item__title">{this.props.name}</h3>
                    <Switch 
                        onChange={this.handleChange} 
                        checked={this.state.checked} 
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={20}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={15}
                        width={43}
                        className="react-switch"
                        id="material-switch"
                    />
                </div>
            </div>
        );
    };
};

export default FiltersPageItem;