import React from 'react';
import { Link } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';

const Header = () => (
  <header className="header__main-menu" style={{ height: '64px' }}>
    <div className="header__main-menu__content" style={{ justifyContent: 'flex-end' }}>
      <Link to="/dashboard">
        <button className="button button--link"><FaSignOutAlt /></button>
      </Link>
    </div>
  </header>
);

export default Header;