import React from 'react';
import validator from 'validator';
import { FaCoins, FaPencilAlt, FaWallet } from "react-icons/fa";

import { transactions } from '../../services/api';

class EditWalletQuantity extends React.Component {
    constructor(props) {
        super(props);
        
        console.log(props);

        this.state = {
            wallet_address: props.location.wallet_address || '',
            crypto_name: props.location.crypto_name || '',
            quantity: props.location.quantity || '',
            errors: [],
            errorType: ''
        };
    };

    onWalletAddressChange = (e) => {
        const wallet_address = e.target.value;

        this.setState(() => ({ wallet_address }));
    }

    onCryptoNameChange = (e) => {
        const crypto_name = e.target.value;

        this.setState(() => ({ crypto_name }));
    }

    onQuantityChange = (e) => {
        const quantity = e.target.value;

        this.setState(() => ({ quantity }));
    }

    validateFields = () => {
        this.setState(() => ({errors: [], errorType: ''}));

        if (!validator.matches(this.state.wallet_address, /^[a-zA-Z0-9]{32}$/)) {
            this.setState(() => ({errors: ['You must enter a valid wallet address'], errorType: 'wallet_address'}));
            return false;
        }
        if (!validator.isAlphanumeric(this.state.crypto_name)) {
            this.setState(() => ({errors: ['You must enter a valid cryptocurrency name'], errorType: 'crypto_name'}));
            return false;
        }
        if (!validator.isDecimal(this.state.quantity, { decimal_digits: '0,2' })) {
            this.setState(() => ({errors: ['You must enter a valid quantity (decimal value)'], errorType: 'quantity'}));
            return false;
        }

        return true;
    }

    onSubmit = async (e) => {
        e.preventDefault();

        if (this.validateFields()) {
            try {
                const res = await transactions.post('transactions/admin/edit-wallet-quantity', {
                    wallet_address: this.state.wallet_address,
                    crypto_name: this.state.crypto_name,
                    quantity: this.state.quantity
                });
    
                this.props.history.push('/admin-panel');
            } catch (err) {
                this.setState(() => ({errors: [err.response.data.errors[0].message], errorType: ''}));
            }
        }
    }

    render() {
        return (
            <form className="register__box" style={{ marginTop: '80px' }} onSubmit={this.onSubmit}>
                <div className="register__box__form" style={{ paddingBottom: '25px' }}>
                    {
                        this.state.errorType === 'wallet_address'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }}>
                            <FaWallet />
                            <input className="register__text-area" type="text" placeholder="Wallet Address" value={this.state.wallet_address} onChange={this.onWalletAddressChange} />
                        </div>
                        :
                        <div className="input-box">
                            <FaWallet />
                            <input className="register__text-area" type="text" placeholder="Wallet Address" value={this.state.wallet_address} onChange={this.onWalletAddressChange} />
                        </div>
                    }
                    {
                        this.state.errorType === 'crypto_name'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }}>
                            <FaPencilAlt />
                            <input className="register__text-area" type="text" placeholder="Crypto Name" value={this.state.crypto_name} onChange={this.onCryptoNameChange} />
                        </div>
                        :
                        <div className="input-box">
                            <FaPencilAlt />
                            <input className="register__text-area" type="text" placeholder="Crypto Name" value={this.state.crypto_name} onChange={this.onCryptoNameChange} />
                        </div>
                    }
                    {
                        this.state.errorType === 'quantity'
                        ? 
                        <div className="input-box" style={{ borderColor: 'red' }}>
                            <FaCoins />
                            <input className="register__text-area" type="text" placeholder="Quantity" value={this.state.quantity} onChange={this.onQuantityChange} />
                        </div>
                        :
                        <div className="input-box">
                            <FaCoins />
                            <input className="register__text-area" type="text" placeholder="Quantity" value={this.state.quantity} onChange={this.onQuantityChange} />
                        </div>
                    }
                </div>
                <div className="register__error-warning">
                        {this.state.errors.length > 0 ? this.state.errors : ''}
                </div>
                <div>
                    <div className="register__box__register-button">
                        <button className="register__box__register-button__style">Edit Wallet Quantity</button>
                    </div>
                </div>
            </form>
        );
    }
};

export default EditWalletQuantity;