import React from 'react';
import { Link } from 'react-router-dom';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { FaCoins, FaPencilAlt, FaPlus, FaUserCog, FaWallet, FaExchangeAlt, FaFileAlt, FaFileDownload, FaCheck } from 'react-icons/fa';
import { DataGrid } from '@material-ui/data-grid';
import fileDownload from 'js-file-download';

const { parse } = require('json2csv');

import { auth, crypto, wallet, market, logs } from '../../services/api';

import '@trendmicro/react-sidenav/dist/react-sidenav.css';

const renderEditButton = (params) => {
    return (   
        <strong>
            <Link 
                to={{
                    pathname: "/admin-panel/edit-crypto",
                    name: params.row.name,
                    symbol: params.row.symbol,
                    price: params.row.price,
                    volume: params.row.volume,
                    privacy: params.row.privacy,
                    transaction_cost: params.row.transaction_cost,
                    settlement_time: params.row.settlement_time
                }} 
                style={{ textDecoration: 'none', border: 'none', background: 'none', color: 'black' }}
            >
                <FaPencilAlt />
            </Link>
        </strong>
    )
};

const renderEditWalletButton = (params) => {
    return (   
        <strong>
            <Link 
                to={{
                    pathname: "/admin-panel/edit-wallet-quantity",
                    wallet_address: params.row.address,
                    crypto_name: params.row.name,
                    quantity: params.row.quantity
                }} 
                style={{ textDecoration: 'none', border: 'none', background: 'none', color: 'black' }}
            >
                <FaPencilAlt />
            </Link>
        </strong>
    )
};

const columnsUser = [
    { field: 'id', headerName: 'ID', type: 'number', width: 80 },
    { field: 'phone_number', headerName: 'Phone Number', width: 130 },
    { field: 'email', headerName: 'Email', width: 130 },
    { field: 'full_name', headerName: 'Full Name', width: 130 },
    { field: 'role', headerName: 'Role', width: 130 }
];

const columnsCrypto = [
    { field: 'id', headerName: 'ID', type: 'number', width: 80 },
    { field: 'name', headerName: 'Name', width: 130 },
    { field: 'symbol', headerName: 'Symbol', width: 100 },
    { field: 'price', headerName: 'Price', type: 'number', width: 100 },
    { field: 'volume', headerName: 'Cap', type: 'number', width: 100 },
    { field: 'privacy', headerName: 'Privacy', width: 100 },
    { field: 'transaction_cost', headerName: 'Fee (%)', type: 'number', width: 150 },
    { field: 'settlement_time', headerName: 'Settlement Time (Hours)', type: 'number', width: 160 },
    {
        field: 'col6',
        headerName: 'Edit',
        width: 80,
        renderCell: renderEditButton,
        disableClickEventBubbling: true
    }
];

const columnsWallet = [
    { field: 'user_id', headerName: 'User ID', type: 'number', width: 100 },
    { field: 'full_name', headerName: 'Full Name', width: 130 },
    { field: 'address', headerName: 'Wallet Address', width: 130 },
    { field: 'name', headerName: 'Crypto Name', width: 100 },
    { field: 'quantity', headerName: 'Quantity', type: 'number', width: 100 },
    {
        field: 'col6',
        headerName: 'Edit',
        width: 80,
        renderCell: renderEditWalletButton,
        disableClickEventBubbling: true
    }
];

const columnsOrderbook = [
    { field: 'id', headerName: 'Order ID', type: 'number', width: 100 },
    { field: 'crypto_name', headerName: 'Crypto Name', width: 100 },
    { field: 'date', headerName: 'Creation Date', width: 130 },
    { field: 'side', headerName: 'Side', width: 130 },
    { field: 'quantity', headerName: 'Quantity', type: 'number', width: 100 },
    { field: 'price', headerName: 'Price', type: 'number', width: 100 },
    { field: 'trader_name', headerName: 'Creator', width: 100 },
    { field: 'type', headerName: 'Type', width: 100 }
];

class AdminPanelPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current_phase: 'trading' || '',
            usersList: [],
            userListLoaded: false,
            cryptosList: [],
            cryptosListLoaded: false,
            walletsList: [],
            walletsListLoaded: false,
            orderbookList: [],
            orderbookListLoaded: false,
            fatalLogsList: [],
            errorLogsList: [],
            warnLogsList: [],
            infoLogsList: [],
            logsListLoaded: false,
            navSelected: 'user'
        };
    };

    componentDidMount() {
        this.getAllUsers();
        this.getAllCryptos();
        this.getAllWallets();
        this.getOrderbook();
        this.getLogs();
        this.getSystemPhase();
    }

    getSystemPhase = async () => {
        try {
            const res = await auth.get('auth/current-phase');
            
            console.log(res);

            let current_phase = res.data.phase;

            this.setState({ current_phase });
        } catch (err) {
            console.log(err);
        }
    }

    getAllUsers = async () => {
        try {
            const res = await auth.get('auth/get-all-users');
            
            let userListAux = res.data.result.map((el) => {
                return {
                    id: el.id_user,
                    email: el.email,
                    phone_number: el.phone_number,
                    full_name: el.full_name,
                    role: el.role
                }
            });

            this.setState({ usersList: userListAux });
            this.setState({ userListLoaded: true });
        } catch (err) {
            console.log(err);
        }
    }

    getAllCryptos = async () => {
        try {
            const res = await crypto.get('crypto/get-all-cryptos');
            
            let cryptoListAux = res.data.result.map((el) => {
                return {
                    id: el.crypto_id,
                    name: el.name,
                    symbol: el.symbol,
                    price: el.price,
                    volume: el.volume,
                    privacy: el.privacy,
                    transaction_cost: el.transaction_cost * 100,
                    settlement_time: (el.settlement_time/60/60).toFixed(2)
                }
            });

            this.setState({ cryptosList: cryptoListAux });
            this.setState({ cryptosListLoaded: true });
        } catch (err) {
            console.log(err);
        }
    }

    getAllWallets = async () => {
        try {
            const res = await wallet.get('wallet/get-all-wallets');
            
            let walletListAux = res.data.result.map((el, index) => {
                return {
                    id: index,
                    user_id: el.user_id,
                    full_name: el.full_name,
                    address: el.address,
                    name: el.name,
                    quantity: el.quantity
                }
            });

            this.setState({ walletsList: walletListAux });
            this.setState({ walletsListLoaded: true });
        } catch (err) {
            console.log(err);
        }
    }

    getOrderbook = async () => {
        try {
            const res = await market.get('market/get-orderbook');

            console.log(res);
            
            let orderbookListAux = res.data.result.map((el, index) => {
                return {
                    id: index,
                    order_id: el.order_id,
                    date: el.date,
                    side: el.side,
                    quantity: el.quantity,
                    price: el.price,
                    type: el.type,
                    crypto_name: el.name,
                    trader_name: el.full_name
                }
            });

            console.log(orderbookListAux);

            this.setState({ orderbookList: orderbookListAux });
            this.setState({ orderbookListLoaded: true });
        } catch (err) {
            console.log(err);
        }
    }

    getLogs = async () => {
        try {
            const res = await logs.get('logs/get-logs');

            console.log(res);
            this.setState({ errorLogsList: res.data.errorLogs });
            this.setState({ fatalLogsList: res.data.fatalLogs });
            this.setState({ warnLogsList: res.data.warnLogs });
            this.setState({ infoLogsList: res.data.infoLogs });
            this.setState({ logsListLoaded: true });
        } catch (err) {
            console.log(err);
        }
    }

    resolveMarket = async () => {
        try {
            const res = await market.post('market/resolve-market');
        } catch (err) {
            console.log(err);
        }
    }

    onPhaseSubmit = async () => {
        let phase = document.querySelector('input[name="trading_transactions"]:checked').value;

        try {
            const res = await auth.post('auth/admin/update-phase', {
                phase
            });
        } catch (err) {
            console.log(err);
        }
    }

    render () {
        return (
            <div>
                <SideNav
                    onSelect={(selected) => {
                        // Add your code here
                        this.setState({ navSelected: selected });
                    }}
                    style={{ background: '#1c88bf' }}
                >
                    <SideNav.Toggle />
                    <SideNav.Nav defaultSelected="user">
                        <NavItem eventKey="user">
                            <NavIcon style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FaUserCog size={25} />
                            </NavIcon>
                            <NavText>
                                Users
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="crypto">
                            <NavIcon style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FaCoins size={25} />
                            </NavIcon>
                            <NavText>
                                Cryptocurrencies
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="wallet">
                            <NavIcon style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FaWallet size={25} />
                            </NavIcon>
                            <NavText>
                                Wallets
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="market">
                            <NavIcon style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FaExchangeAlt size={25} />
                            </NavIcon>
                            <NavText>
                                Market
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="logs">
                            <NavIcon style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FaFileAlt size={25} />
                            </NavIcon>
                            <NavText>
                                Logs
                            </NavText>
                        </NavItem>
                    </SideNav.Nav>
                </SideNav>
                {
                    this.state.navSelected === 'user' && this.state.userListLoaded &&
                    <div style={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', paddingTop: '100px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '80%' }}>
                            <div 
                                style={{ alignContent: 'center', display: 'flex', justifyContent: 'space-around', width: '40%' }}
                            >
                                <div style={{ alignSelf: 'center' }}>
                                    {
                                        this.state.current_phase === 'trading' ?
                                        <input type="radio" name="trading_transactions" id="trading" value="trading" defaultChecked/> : 
                                        <input type="radio" name="trading_transactions" id="trading" value="trading" />
                                    }
                                    <label style={{ fontWeight: 'bold', marginLeft: '5px' }} htmlFor="trading">Trading</label>
                                </div>
                                <div style={{ alignSelf: 'center' }}>
                                    {
                                        this.state.current_phase === 'transactions' ?
                                        <input type="radio" name="trading_transactions" id="transactions" value="transactions" defaultChecked/> : 
                                        <input type="radio" name="trading_transactions" id="transactions" value="transactions" />
                                    }
                                    <label style={{ fontWeight: 'bold', marginLeft: '5px' }} htmlFor="transactions">Transactions</label>
                                </div>
                                <div>
                                    <button
                                        className="register__box__register-button__style"
                                        onClick={this.onPhaseSubmit}
                                    >
                                        Save Experience Phase
                                    </button>
                                </div>
                            </div>
                        </div>
                        <h2>Users</h2>
                        <div className="register__box__register-button" style={{ marginBottom: '5px' }}>
                            <Link 
                                to="/admin-panel/add-user"
                                className="register__box__register-button__style"
                                style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '150px' }}
                            >
                                <FaPlus style={{ paddingRight: '5px' }} /> Invite User
                            </Link>
                        </div>
                        <div style={{ height: 400, width: '45%' }}>
                            <DataGrid rows={this.state.usersList} columns={columnsUser} pageSize={5} />
                        </div>
                    </div>
                }
                {
                    this.state.navSelected === 'crypto' && this.state.cryptosListLoaded &&
                    <div style={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', paddingTop: '100px' }}>
                        <h2>Cryptocurrencies</h2>
                        <div className="register__box__register-button" style={{ marginBottom: '5px' }}>
                            <Link 
                                to="/admin-panel/add-crypto"
                                className="register__box__register-button__style"
                                style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            >
                                <FaPlus style={{ paddingRight: '5px' }} /> Add Cryptocurrency
                            </Link>
                        </div>
                        <div style={{ height: 400, width: '60%' }}>
                            <DataGrid rows={this.state.cryptosList} columns={columnsCrypto} pageSize={5} />
                        </div> 
                    </div>
                }
                {
                    this.state.navSelected === 'wallet' && this.state.walletsListLoaded &&
                    <div style={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', paddingTop: '100px' }}>
                        <h2>Wallets</h2>
                        <div style={{ height: 400, width: '50%' }}>
                            <DataGrid rows={this.state.walletsList} columns={columnsWallet} pageSize={5} />
                        </div> 
                    </div>
                }
                {
                    this.state.navSelected === 'market' && this.state.orderbookListLoaded &&
                    <div style={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', paddingTop: '100px' }}>
                        <h2>Orderbook</h2>
                        <div className="register__box__register-button" style={{ marginBottom: '5px' }}>
                            <Link 
                                to="/admin-panel/set-auction-times"
                                className="register__box__register-button__style"
                                style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            >
                                <FaPlus style={{ paddingRight: '5px' }} /> Set Auction Times
                            </Link>
                        </div>
                        <div style={{ height: 400, width: '50%' }}>
                            <DataGrid rows={this.state.orderbookList} columns={columnsOrderbook} pageSize={5} />
                        </div>
                        <div className="register__box__register-button" style={{ marginBottom: '5px' }}>
                            <button 
                            className="register__box__register-button__style" 
                            style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            onClick={this.resolveMarket}
                            >
                                <FaCheck style={{ paddingRight: '5px' }} /> Resolve Market Now
                            </button>
                        </div> 
                    </div>
                }
                {
                    this.state.navSelected === 'logs' && this.state.logsListLoaded &&
                    <div style={{ height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', paddingTop: '100px' }}>
                        <h2>Logs</h2>
                        <div className="log_page">
                            <button className="log_page-button" onClick={() => {
                                const fields = ['log'];
                                const opts = { fields };

                                try{
                                    const csv = parse(this.state.fatalLogsList, opts);
                                    
                                    fileDownload(csv, 'fatalLogs.csv');
                                } catch(err) {
                                    console.error(err);
                                }

                            }}>
                                <FaFileDownload />
                                Logs of Fatal Level
                            </button>
                            <button className="log_page-button" onClick={() => {
                                const fields = ['log'];
                                const opts = { fields };

                                try{
                                    const csv = parse(this.state.errorLogsList, opts);
                                    
                                    fileDownload(csv, 'errorLogs.csv');
                                } catch(err) {
                                    console.error(err);
                                }

                            }}>
                                <FaFileDownload />
                                Logs of Error Level
                            </button>
                            <button className="log_page-button" onClick={() => {
                                const fields = ['log'];
                                const opts = { fields };

                                try{
                                    const csv = parse(this.state.warnLogsList, opts);
                                    
                                    fileDownload(csv, 'warnLogs.csv');
                                } catch(err) {
                                    console.error(err);
                                }

                            }}>
                                <FaFileDownload />
                                Logs of Warning Level
                            </button>
                            <button className="log_page-button" onClick={() => {
                                const fields = ['log'];
                                const opts = { fields };

                                try{
                                    const csv = parse(this.state.infoLogsList, opts);

                                    fileDownload(csv, 'infoLogs.csv');
                                } catch(err) {
                                    console.error(err);
                                }

                            }}>
                                <FaFileDownload />
                                Logs of Info Level
                            </button>
                        </div>
                    </div>
                }
            </div>
        );
    }
};

export default AdminPanelPage;
