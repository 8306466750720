import React from 'react';
import { FooterSecondaryPaySend } from '../FooterSecondary';
import SendPageForm from './SendPageForm';

import { transactions } from '../../services/api';

class SendPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cryptos: [],
            dataLoaded: false
        };
    }

    componentDidMount() {
        this.getCryptosAvailable();
    }

    getCryptosAvailable = async () => {
        try {
            const res = await transactions.get('transactions/get-cryptos');
            this.setState({ cryptos: res.data.result });
            this.setState({ walletAddress: res.data.wallet_address });
            this.setState({ dataLoaded: true });
        } catch (err) {
            console.log(err);
        }
    }

    render () {
        return (
            this.state.dataLoaded && (
                <div>
                    <SendPageForm cryptos={this.state.cryptos} {...this.props} />
                    <FooterSecondaryPaySend titleOne="Pay" titleSecond="Send" {...this.props} />
                </div>
            )
        );
    }
};

export default SendPage;