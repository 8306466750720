import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';

const InitialHeader = (props) => (
    <header className="header">
        <div className="header__title-container">
            <h3 className="header__title">{props.title}</h3>
        </div> 
        <div className="header__button-container">
            <button className="header__button" onClick={() => props.history.goBack()} ><FaArrowLeft /></button>
        </div>
    </header>
);

export default InitialHeader;