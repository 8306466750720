import React from 'react';
import { OrderListItemOpen, OrderListItemClosed } from './OrderListItem';

import { market } from '../../services/api'; 

export class PortfolioOrdersOpenPage extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            ordersOpen: [],
            ordersOpenLoaded: false
        }
    }

    componentDidMount() {
        this.getAsks();
        this.getBids();
    }

    getAsks = async () => {
        try {
            const res = await market.get('market/get-asks');
            this.setState((prevState) => ({ordersOpen: prevState.ordersOpen.concat(res.data.result)}));
        } catch (err) {
            console.log(err);
        }
    }

    getBids = async () => {
        try {
            const res = await market.get('market/get-bids');
            this.setState((prevState) => ({ordersOpen: prevState.ordersOpen.concat(res.data.result)}));
            this.setState({ ordersOpenLoaded: true });
        } catch (err) {
            console.log(err);
        }
    }

    render () {
        return (
            <div>
                <div className="portfolio__page">
                    <div className="portfolio__header">
                        <button className="portfolio__header__button-active" onClick={() => this.props.history.push('/my-orders-open')}>Open</button>
                        <button className="portfolio__header__button-inactive" onClick={() => this.props.history.push('/my-orders-closed')}>Closed</button>
                    </div>
                    {
                        this.state.ordersOpenLoaded && 
                        <div className="portfolio__list-body" style={{ marginBottom: '95px' }}>
                            {
                                this.state.ordersOpen.length === 0 ? (
                                <div className="portfolio__list-item portfolio__list-item--message">
                                    <span style={{ fontWeight: 'bold' }}>There are no orders</span>
                                </div>
                                ) : (
                                    this.state.ordersOpen.map((order, index) => {
                                        return <OrderListItemOpen key={index} history={this.props.history} {...order} />;
                                    })
                                )
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }
};

export class PortfolioOrdersClosedPage extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            ordersHistory: [],
            ordersHistoryLoaded: false
        }
    }

    componentDidMount() {
        this.askHistory();
        this.bidHistory();
    }

    askHistory = async () => {
        try {
            const res = await market.get('market/get-ask-history');
            this.setState((prevState) => ({ordersHistory: prevState.ordersHistory.concat(res.data.result)}));
        } catch (err) {
            console.log(err);
        }
    }

    bidHistory = async () => {
        try {
            const res = await market.get('market/get-bid-history');
            this.setState((prevState) => ({ordersHistory: prevState.ordersHistory.concat(res.data.result)}));
            this.setState({ ordersHistoryLoaded: true });
        } catch (err) {
            console.log(err);
        }
    }

    render () {
        return (
            <div>
                <div className="portfolio__page">
                    <div className="portfolio__header">
                        <button className="portfolio__header__button-inactive" onClick={() => this.props.history.push('/my-orders-open')}>Open</button>
                        <button className="portfolio__header__button-active" onClick={() => this.props.history.push('/my-orders-closed')}>Closed</button>
                    </div>
                    {
                        this.state.ordersHistoryLoaded &&
                        <div className="portfolio__list-body" style={{ marginBottom: '95px' }}>
                            {
                                this.state.ordersHistory.length === 0 ? (
                                    <div className="portfolio__list-item portfolio__list-item--message">
                                    <span style={{ fontWeight: 'bold' }}>There are no orders</span>
                                    </div>
                                ) : (
                                    this.state.ordersHistory.map((order, index) => {
                                        return <OrderListItemClosed key={index} {...order} />;
                                    })
                                )
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }
};

/*

<FooterSecondaryPortfolio titleOne="Positions" titleSecond="Orders" {...this.props} />

*/
