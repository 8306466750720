import React from 'react';
import { FooterSecondaryPortfolio } from '../FooterSecondary';
import { PositionListItemOpen, PositionListItemClosed } from './PositionListItem';

export class PortfolioPositionsOpenPage extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            positions: [{
                name: 'Crypto #1 (€)',
                price: '54.4'
            },
            {
                name: 'Crypto #2 (€)',
                price: '4.4'
            },
            {
                name: 'Crypto #3 (€)',
                price: '534.4'
            }]
        }
    }

    render () {
        return (
            <div>
                <div className="portfolio__page">
                    <div className="portfolio__header">
                        <button className="portfolio__header__button-active" onClick={() => this.props.history.push('/portfolio-positions-open')}>Open</button>
                        <button className="portfolio__header__button-inactive" onClick={() => this.props.history.push('/portfolio-positions-closed')}>Closed</button>
                    </div>
                    <div className="portfolio__list-body">
                        {
                            this.state.positions.length === 0 ? (
                            <div className="list-item list-item--message">
                                <span>All positions are hidden</span>
                            </div>
                            ) : (
                                this.state.positions.map((position) => {
                                    return <PositionListItemOpen key={position.name} {...position} />;
                                })
                            )
                        }
                    </div>
                </div>
                <FooterSecondaryPortfolio titleOne="Positions" titleSecond="Orders" {...this.props} />
            </div>
        );
    }
};

export class PortfolioPositionsClosedPage extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            positions: [{
                name: 'Crypto #1 (€)',
                price: '54.4'
            },
            {
                name: 'Crypto #2 (€)',
                price: '4.4'
            },
            {
                name: 'Crypto #3 (€)',
                price: '534.4'
            }]
        }
    }

    render () {
        return (
            <div>
                <div className="portfolio__page">
                    <div className="portfolio__header">
                        <button className="portfolio__header__button-inactive" onClick={() => this.props.history.push('/portfolio-positions-open')}>Open</button>
                        <button className="portfolio__header__button-active" onClick={() => this.props.history.push('/portfolio-positions-closed')}>Closed</button>
                    </div>
                    <div className="portfolio__list-body">
                        {
                            this.state.positions.length === 0 ? (
                            <div className="list-item list-item--message">
                                <span>All positions are hidden</span>
                            </div>
                            ) : (
                                this.state.positions.map((position) => {
                                    return <PositionListItemClosed key={position.name} {...position} />;
                                })
                            )
                        }
                    </div>
                </div>
                <FooterSecondaryPortfolio titleOne="Positions" titleSecond="Orders" {...this.props} />
            </div>
        );
    }
};